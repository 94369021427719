import axios from 'axios'
import React, { useState } from 'react'
import fileDownload from 'js-file-download'

import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { API_URI } from '../../constants/GlobalConstants'


const InvoiceApp = ({ nav }) => {

    const [name, setName] = useState('')
    const [salesPerson, setSalesPerson] = useState('');
    const [date, setDate] = useState(dayjs());
    const [des, setDes] = useState('');
    const [pro1, setPro1] = useState('');
    const [pro1Price, setPro1Price] = useState(0.00);
    const [pro2, setPro2] = useState('');
    const [pro2Price, setPro2Price] = useState(0.00);
    const [action, setAction] = useState('');



    const handleSubmit = async (e) => {
        e.preventDefault()
        const val = {
            name, salesPerson, action, date: date.$d.toString().slice(4, 15),
            pro1, pro1Price, pro2, pro2Price, des
        }
        await axios.post(`${API_URI}/api/app/invoice-get-data`, val)
        const res = await axios.get(`${API_URI}/api/app/invoice-generator`, {
            responseType: 'blob',
        })

        console.log(res.data)
        // const res = await axios.get(`${API_URI}/api/app/invoice-generator`, {
        //     responseType: 'blob',
        // })
        await fileDownload(res.data, `DSSBlinds-${action}-${name}.pdf`)

        // fileDownload(res.data, `DBS-Blinds-Invoice_${name}.pdf`)
        // 

        // setDdd(res.data)

        // const pdfBuffer = await new Buffer.from(res.data, 'base64')
        // fs.writeFileSync('dfsd.pdf', pdfBuffer)

        // console.log(res)
        // function debugBase64(base64URL) {
        //     var win = window.open();
        //     win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        // }
        // if (res) {
        //     debugBase64(`data:application/pdf;base64,${res.data}`)
        // }

    }

    console.log(date.$d.toString().slice(0, 15))

    return (
        <>
            <div className={nav.isSidebar}>
                <Header />
                <Sidebar nav={nav} />
                {/*start content*/}
                <main className="page-content">
                    {/*breadcrumb*/}
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Dashboard</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">
                                            <i className="bx bx-home-alt" />
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Create Invoice
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/*end breadcrumb*/}
                    <div className="row">
                        <form onSubmit={handleSubmit}>
                            <div className="col-lg-12 mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-12">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <h4>Invoice Details</h4>
                                                            <div className="col-lg-4 col-md-4">
                                                                <label className="form-label"> Invoice/ to</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="To"
                                                                    // required
                                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <label className="form-label"> Salesperson</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Invoice to"
                                                                    // required
                                                                    value={salesPerson} onChange={(e) => setSalesPerson(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <label className="form-label"> Action</label>
                                                                <select className="form-select"
                                                                    value={action} onChange={(e) => setAction(e.target.value)}>
                                                                    <option></option>
                                                                    <option value='Estimation'>Estimation</option>
                                                                    <option value='Invoice'>Invoice</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="bg-secondary p-2">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <MobileDatePicker
                                                                            label="Date mobile"
                                                                            inputFormat="MM/DD/YYYY"
                                                                            value={date}
                                                                            onChange={(newValue) => {
                                                                                setDate(newValue)
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <h5>Product Pricing</h5>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 border-end border-1">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6 ">
                                                                            <label className="form-label"> Product 1</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Product"
                                                                                // required
                                                                                value={pro1} onChange={(e) => setPro1(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <label className="form-label">Price $</label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                placeholder="Price"
                                                                                required
                                                                                value={pro1Price} onChange={(e) => setPro1Price(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <label className="form-label"> Product 2</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Product"
                                                                                value={pro2} onChange={(e) => setPro2(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <label className="form-label">Price $</label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                placeholder="Price "
                                                                                value={pro2Price} onChange={(e) => setPro2Price(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />

                                                            <div className="col-12">
                                                                <label className="form-label">Description / Note</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Full description"
                                                                    rows={2}
                                                                    cols={2}
                                                                    defaultValue={""}
                                                                    value={des} onChange={(e) => setDes(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end row*/}
                                    </div>
                                    <div className="card-header py-3 bg-transparent">
                                        <div className="d-sm-flex align-items-center">
                                            <h5 className="mb-2 mb-sm-0">Invoice generator</h5>
                                            <div className="ms-auto">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onSubmit={handleSubmit}>
                                                    Create Invoice
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    {/*end row*/}
                </main>

                <div className="overlay nav-toggle-icon" />
                <a href="" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt" />
                </a>
            </div>
        </>
    )
}

export default InvoiceApp