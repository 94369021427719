export const createProductReducer = (state = {}, action) => {
    switch (action.type) {
        case 'PRODUCT_CREATE_REQUEST':
            return { loading: true }
        case 'PRODUCT_CREATE_SUCCESS':
            return { loading: false, success: true }
        case 'PRODUCT_CREATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const listAttributeReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ATTRIBUTE_LIST_REQUEST':
            return { loading: true }
        case 'ATTRIBUTE_LIST_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'ATTRIBUTE_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}