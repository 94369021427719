export const eighths = ['1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8']
export const eighthsValue = [.125, .250, .375, .500, .625, .750, .875]

//45.25 => 45, 25
export function splitFraction(val) {
    return val.toString().split('.')
}

//3/8 => .375 
export function getDecimal(val) {
    const i = eighths.indexOf(val);
    return eighthsValue[i]
}

//.375 => 3/8
export function getFraction(val) {
    const i = eighthsValue.indexOf(val)
    return eighths[i]

}

//45 1/2 => 45.5 
export function totalDecimal(val) {
    const x = val.split(' ')
    return (
        parseFloat(x[0]) + (x[1] ? parseFloat(getDecimal(x[1])) : 0)
    )
}

//.489 => .375 → get the decimal value and returns the 8th fraction
export function getClosestDecimal(val) {
    val = parseFloat(val)
    const closest = eighthsValue.reduce((a, b) => {
        return Math.abs(b - val) < Math.abs(a - val) ? b : a;
    });
    return closest;
}

//45.5 => 45 1/2
export function getFinalVal(val) {
    const xVal = splitFraction(val)
    return (xVal[0] + ' ' + (xVal[1] ? getFraction(parseFloat('.' + xVal[1])) : ''))
}


//45.458 => 45 1/2
export function cvrtDeci2Frac(val) {
    //[45, 45] = val
    const [num, deci] = splitFraction(val)
    return (parseFloat(num) + ' ' + (deci ? (getFraction(getClosestDecimal('.' + deci))) : ('')))
}


//geting the nearest value inside a 2D array
export function nearestGreaterVal(array, val) {
    // Short-circuit the code if the array is empty.
    if (array.length === 0) {
        return { next: null }
    }
    // Find the index of the closest greater number.
    const closestIndex = array.findIndex(v => v >= val)
    // A non-existent/negative index is undefined. No need for extensive checks.
    const closestNext = array[closestIndex] || null
    // const closestPrev = array[closestIndex - 1] || null
    const lastValue = array[array.length - 1] || null
    // The only special case is the non-existent value (index = -1)
    const next = closestIndex === -1 ? null : closestNext
    // const prev = closestIndex === -1 ? lastValue : closestPrev
    // Build the instance (a simple POJO in this case).
    return next
}