import { cvrtDeci2Frac, totalDecimal } from "../../../../functions/productionSheet/GolbalFunctions"
import { splitValidation } from "../../functions/OrderFunctions"

//When the split button is triggered then this function will split the width into 2 eqaul part ie:r&l
export function enableSplit(width, splitWidth, setSplitWidth, e) {
    if (width) {
        setSplitWidth({ l: cvrtDeci2Frac((totalDecimal(width)) / 2), r: cvrtDeci2Frac((totalDecimal(width)) / 2), m: 0, trigger: true })
    }
}

//----- Split Function🖖🏽: fun will split the width into max 3 widths
export const SplitInput = ({ setSplitWidth, splitWidth, width }) => {
    //When ever change in the input split fields this function will get called
    const handleSplitChange = (e) => {
        //getting values from user input
        const { name, value } = e.target
        setSplitWidth({ ...splitWidth, [name]: value })
        splitValidation(width, name, value, setSplitWidth, splitWidth)
    }
    const handleSplitSubmit = (e) => {

    }
    return (
        <>

            <div className="col-2">
                <label className="form-label">Left</label>
                <input type="text" className="form-control" placeholder="Left"
                    value={splitWidth.l} onChange={handleSplitChange} name='l'
                />
            </div>
            <div className="col-2">
                <label className="form-label">Right</label>
                <input type="text" className="form-control" placeholder="Right"
                    value={splitWidth.r} onChange={handleSplitChange} name="r"
                />
            </div>
            <div className="col-2">
                <label className="form-label">Middle</label>
                <input type="text" className="form-control" placeholder="Middle"
                    value={splitWidth.m} onChange={handleSplitChange} name="m"
                    disabled
                />
            </div>
            <div className="col-3">
                <label className="form-label">Remark</label>
                <input type="text" className="form-control" placeholder="Remarks, controls..."
                // value={remark} onChange={(e) => setRemark(e.target.value)}
                />
            </div>
            <div className="col-1 mt-4 mx-3 " >
                <form onSubmit={handleSplitSubmit}>
                    <button type='submit' onSubmit={handleSplitSubmit}
                        className="mt-1 btn btn-success">
                        Submit
                    </button>
                </form>
            </div>
            <div className="col-1 mt-4 float-right" >
                <form>
                    <button type='submit' onClick={(e) => setSplitWidth({ ...splitWidth, trigger: false })}
                        className="mt-1 btn btn-danger">
                        Cancel
                    </button>
                </form>
            </div>
        </>
    )
}