export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST'
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS'
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL'

export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST'
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS'
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL'

export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST'
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS'
export const CLIENT_UPDATE_FAIL = 'CLIENT_UPDATE_FAIL'