import { Link } from "react-router-dom"

const OrderList = ({ p }) => {
    return (
        <div className="table-responsive mt-3">
            <table className="table align-middle mb-0">
                <thead className="table-light">
                    <tr>
                        <th>Dealer</th>
                        <th>Product</th>
                        <th>Material</th>
                        <th># products</th>
                        <th>Status</th>
                    </tr>
                </thead>
                {p && p.map((p) =>
                    <>
                        <tbody>
                            <tr>
                                <td><Link to={`/order/order-detail/${p._id}`}>{p.dealerName}</Link></td>
                                <td><Link className='text-white' to={`/order/order-detail/${p._id}`}>{p.productType}</Link></td>
                                <td><Link className='text-white' to={`/order/order-detail/${p._id}`}>{p.productMaterial}</Link></td>
                                <td><Link className='text-white' to={`/order/order-detail/${p._id}`}>{p.orderArray.length}</Link></td>
                                <td>
                                    <span className="badge bg-light-success text-success w-55">
                                        {p.action}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </>
                )
                }


            </table>
        </div>
    )
}

export default OrderList