import axios from "axios"
import { DEALER_CREATE_FAIL, DEALER_CREATE_REQUEST, DEALER_CREATE_SUCCESS, DEALER_LIST_FAIL, DEALER_LIST_REQUEST, DEALER_LIST_SUCCESS } from "../../constants/DealerConstant"
import { API_URI } from "../../constants/GlobalConstants"

export const CreateClientAction = (form) => async (dispatch) => {
    try {
        dispatch({ type: DEALER_CREATE_REQUEST })
        const { data } = await axios.post(`${API_URI}/api/dealer/create`, form)
        dispatch({ type: DEALER_CREATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: DEALER_CREATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const ListDealerAction = () => async (dispatch) => {
    try {
        dispatch({ type: DEALER_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/dealer/list`)
        dispatch({ type: DEALER_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: DEALER_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const ViewDealerAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'DEALER_DETAIL_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/dealer/dealer-detail/${id}`)
        dispatch({ type: 'DEALER_DETAIL_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'DEALER_DETAIL_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const UpdateDealerNotesAction = (val) => async (dispatch) => {
    console.log("🚀 ~ file: DealerAction.js:52 ~ UpdateDealerNotesAction ~ val:", val)
    try {
        dispatch({ type: 'DEALER_UPDATE_NOTE_REQUEST' })
        await axios.put(`${API_URI}/api/dealer/update-notes`, val)
        dispatch({ type: 'DEALER_UPDATE_NOTE_SUCCESS' })
    } catch (error) {
        dispatch({
            type: 'DEALER_UPDATE_NOTE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

//-----------------------Dealer Order-------------------------
export const DealerOrderStatsAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'DEALER_ORDER_STAT_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/dealer/order-overview/${id}`)
        dispatch({ type: 'DEALER_ORDER_STAT_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'DEALER_ORDER_STAT_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}