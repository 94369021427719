import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SignImAction } from '../../redux/actions/UserActions'

const SignIn = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const userLogin = useSelector(state => state.userSignIn)
    const { loading, error, userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            navigate('/')
        }
    }, [userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(SignImAction(email, password))
    }
    return (
        <>
            <div className="wrapper">
                {/*start content*/}
                <main className="authentication-content">
                    <div className="container-fluid">
                        <div className="authentication-card">
                            <div className="card shadow rounded-0 overflow-hidden">
                                <div className="row g-0">
                                    <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                                        <img
                                            src="assets/images/error/login-img.jpg"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>

                                    <div className="col-lg-6 ">
                                        <div className="card-body p-4 p-sm-5">
                                            <h5 className="card-title">Sign In</h5>
                                            <p className="card-text mb-5">
                                                Manage all the prospects, customers, partners and sales management in a single place.
                                            </p>
                                            <form className="form-body" onSubmit={submitHandler}>
                                                <div className="d-grid">
                                                    <a className="btn btn-white radius-30" href="javascript:;">
                                                        <span className="d-flex justify-content-center align-items-center">
                                                            <img
                                                                className="me-2"
                                                                src="assets/images/icons/search.svg"
                                                                width={16}
                                                                alt=""
                                                            />
                                                            <span>Sign in with Google</span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="login-separater text-center mb-4">
                                                    {" "}
                                                    <span className="bg-secondary">OR SIGN IN WITH EMAIL</span>
                                                    <hr />
                                                </div>
                                                <div className="row g-3">
                                                    <div className="col-12">
                                                        <label htmlFor="inputEmailAddress" className="form-label">
                                                            Email Address
                                                        </label>
                                                        <div className="ms-auto position-relative">
                                                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                                                <i className="bi bi-envelope-fill" />
                                                            </div>
                                                            <input
                                                                type="email"
                                                                className="form-control radius-30 ps-5"
                                                                id="inputEmailAddress"
                                                                placeholder="Email Address"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label
                                                            htmlFor="inputChoosePassword"
                                                            className="form-label"
                                                        >
                                                            Enter Password
                                                        </label>
                                                        <div className="ms-auto position-relative">
                                                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                                                <i className="bi bi-lock-fill" />
                                                            </div>
                                                            <input
                                                                type="password"
                                                                className="form-control radius-30 ps-5"
                                                                id="inputChoosePassword"
                                                                placeholder="Enter Password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="flexSwitchCheckChecked"
                                                                defaultChecked=""
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="flexSwitchCheckChecked"
                                                            >
                                                                Remember Me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {" "}
                                                        <a href="/sign-in">
                                                            Forgot Password ?
                                                        </a>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary radius-30"
                                                                onSubmit={submitHandler}
                                                            >
                                                                Sign In
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/*end page main*/}
            </div>
        </>
    )
}

export default SignIn