import React from 'react'
import LoaderLin from '../../layout/LoaderLin'

const Overview = (c) => {

    return (
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0 text-secondary">Appointments</p>
                                <h4 className="my-1">{c.counts.appoints}</h4>
                                <p className="mb-0 font-13 text-success">
                                    <i className="bi bi-caret-up-fill" /> New appointments
                                </p>
                            </div>
                            <div className="widget-icon-large bg-gradient-purple text-white ms-auto">
                                <i className="fadeIn animated bx bx-calendar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0 text-secondary">Total Productions</p>
                                <h4 className="my-1">{c.counts.production}</h4>
                                <p className="mb-0 font-13 text-success">
                                    <i className="bi bi-caret-up-fill" /> Production queue
                                </p>
                            </div>
                            <div className="widget-icon-large bg-gradient-success text-white ms-auto">
                                <i className="fadeIn animated bx bx-wrench" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0 text-secondary">Payment Pending</p>
                                <h4 className="my-1">${c.counts.pPending}</h4>
                                <p className="mb-0 font-13 text-success">
                                    <i className="bi bi-caret-up-fill" />
                                </p>
                            </div>
                            <div className="widget-icon-large bg-gradient-danger text-white ms-auto">
                                <i class="fadeIn animated bx bx-dollar-circle " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0 text-secondary">#Clients pending</p>
                                <h4 className="my-1">{c.counts.pCount}</h4>
                                <p className="mb-0 font-13 text-success">
                                    <i className="bi bi-caret-up-fill" />
                                </p>
                            </div>
                            <div className="widget-icon-large bg-gradient-info text-white ms-auto">
                                <i className="lni lni-users" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview