import {
    APPOINT_CREATE_FAIL, APPOINT_CREATE_REQUEST, APPOINT_CREATE_SUCCESS,
    APPOINT_DELETE_FAIL, APPOINT_DELETE_REQUEST, APPOINT_DELETE_SUCCESS,
    APPOINT_DETAIL_FAIL, APPOINT_DETAIL_REQUEST, APPOINT_DETAIL_SUCCESS,
    APPOINT_LIST_FAIL, APPOINT_LIST_REQUEST, APPOINT_LIST_SUCCESS,
    APPOINT_UPDATE_FAIL, APPOINT_UPDATE_REQUEST, APPOINT_UPDATE_SUCCESS,
    ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS,
    P_PENDING_LIST_FAIL, P_PENDING_LIST_REQUEST, P_PENDING_LIST_SUCCESS,
    TASK_LIST_FAIL, TASK_LIST_REQUEST, TASK_LIST_SUCCESS
} from "../../constants/AppointConstants";

export const AppointListReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINT_LIST_REQUEST:
            return { loading: true, appoint: [] }
        case APPOINT_LIST_SUCCESS:
            return { loading: false, success: true, appoints: action.payload }
        case APPOINT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const OrderListReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
            return { loading: true, orders: [] }
        case ORDER_LIST_SUCCESS:
            return { loading: false, success: true, orders: action.payload }
        case ORDER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const ListCompletedTaskReducer = (state = {}, action) => {
    switch (action.type) {
        case TASK_LIST_REQUEST:
            return { loading: true, task: [] }
        case TASK_LIST_SUCCESS:
            return { loading: false, success: true, task: action.payload }
        case TASK_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const ListPaymentPendingReducer = (state = {}, action) => {
    switch (action.type) {
        case P_PENDING_LIST_REQUEST:
            return { loading: true, orders: [] }
        case P_PENDING_LIST_SUCCESS:
            return { loading: false, success: true, orders: action.payload }
        case P_PENDING_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const ListQuotationReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ACTION_LIST_REQUEST':
            return { loading: true, actions: [] }
        case 'ACTION_LIST_SUCCESS':
            return { loading: false, success: true, actions: action.payload }
        case 'ACTION_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const ListInquiresReducer = (state = {}, action) => {
    switch (action.type) {
        case 'IG_LIST_REQUEST':
            return { loading: true, actions: [] }
        case 'IG_LIST_SUCCESS':
            return { loading: false, success: true, actions: action.payload }
        case 'IG_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const AppointCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINT_CREATE_REQUEST:
            return { loading: true, appoint: [] }
        case APPOINT_CREATE_SUCCESS:
            return { loading: false, success: true }
        case APPOINT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const AppointDetailReducer = (state = { appoint: {} }, action) => {
    switch (action.type) {
        case APPOINT_DETAIL_REQUEST:
            return { loading: true, appoint: {} }
        case APPOINT_DETAIL_SUCCESS:
            return { loading: false, appoint: action.payload, success: true }
        case APPOINT_DETAIL_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const AppointUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINT_UPDATE_REQUEST:
            return { loading: true, }
        case APPOINT_UPDATE_SUCCESS:
            return { loading: false, success: true, appoint: action.payload }
        case APPOINT_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case 'APPOINT_UPDATE_RESET':
            return {}
        default:
            return state;
    }
}

export const AppointDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case APPOINT_DELETE_REQUEST:
            return { loading: true, }
        case APPOINT_DELETE_SUCCESS:
            return { loading: false, success: true }
        case APPOINT_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}