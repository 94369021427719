export const widthArray = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144]
export const heightArray = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144]
export const wMatrix = [
    [9, 9, 9, 9, 9, 9, 9, 9, 9, 10, 11, 12],
    [9, 9, 9, 9, 10, 12, 14, 16, 18, 20, 22, 24],
    [9, 9, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
    [9, 9, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48],
    [9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    [9, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72],
    [9, 14, 21, 28, 35, 42, 49, 56, 63, 70, 77, 84],
    [9, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
    [9, 18, 27, 36, 45, 54, 63, 72, 81, 90, 99, 108],
    [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
    [11, 22, 33, 44, 55, 66, 77, 88, 99, 110, 121, 132],
    [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144]
]