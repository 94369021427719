import axios from 'axios'
import { CLIENT_CREATE_SUCCESS, CLIENT_CREATE_REQUEST, CLIENT_CREATE_FAIL, CLIENT_LIST_REQUEST, CLIENT_LIST_SUCCESS, CLIENT_LIST_FAIL } from '../../constants/ClientConstants'
import { API_URI } from '../../constants/GlobalConstants'

export const ListClientAction = () => async (dispatch) => {
    try {
        dispatch({ type: CLIENT_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/client/list-clients`)
        dispatch({ type: CLIENT_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: CLIENT_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const CreateClientAction = (form) => async (dispatch) => {
    try {
        dispatch({ type: CLIENT_CREATE_REQUEST })
        const { data } = await axios.post(`${API_URI}/api/client/create`, form)
        dispatch({ type: CLIENT_CREATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: CLIENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}
