import React from 'react'
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import ListAppointment from './pages/appointmentManagement/ListAppointment'

import './App.css'
import Home from './pages/Home'
import AddClient from './pages/clientManagement/AddClient'
import ClientList from './pages/clientManagement/ClientList'
import AddAppointment from './pages/appointmentManagement/AddAppointment'
import SignIn from './pages/auth/SignIn'
import DetailAppointment from './pages/appointmentManagement/DetailAppointment'
import EditAppointment from './pages/appointmentManagement/EditAppointment'
import CreateDealer from './pages/dealerManagement/CreateDealer'
import ListDealer from './pages/dealerManagement/ListDealer'
import AddOrder from './pages/orderManagement/AddOrder'
import InvoiceApp from './apps/invoiceGenerator/InvoiceApp'
import AddAction from './pages/actionManagement/AddAction'
import ViewAction from './pages/actionManagement/ViewAction'
import OrderPage from './public/page/OrderPage'
import ListOrder from './pages/orderManagement/ListOrder'
import DetailOrder from './pages/orderManagement/DetailOrder'
import ListInventory from './pages/inventoryManagement/ListInventory'
import EditDealer from './pages/dealerManagement/EditDealer'
import ViewDealer from './pages/dealerManagement/ViewDealer'
import AccountView from './pages/dealerManagement/AccountView'
import { useState } from 'react'
import SalesAnalytics from './pages/analytics/sales/SalesAnalytics'
import ProductionAnalytics from './pages/analytics/productions/ProductionAnalytics'
import Profile from './pages/dealerManagement/Profile'
import AddProductAttribute from './pages/productManagement/AddProductAttribute'

const App = () => {
  const [isSidebar, setIsSidebar] = useState('wrapper');
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home nav={{ isSidebar, setIsSidebar }} />} />
        {/* Authentication */}
        <Route path='/sign-in' element={<SignIn />} />
        {/* Actions */}
        <Route path='/action/add-action' element={<AddAction nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/action/list-action' element={<ViewAction nav={{ isSidebar, setIsSidebar }} />} />
        {/* Appointment Management Routes */}
        <Route path='/appointments' element={<ListAppointment nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/add-appointment' element={<AddAppointment nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/detail-appointment/:id' element={<DetailAppointment nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/edit-appointment/:id' element={<EditAppointment nav={{ isSidebar, setIsSidebar }} />} />
        {/* Client Management Routes */}
        <Route path='/list-client' element={<ClientList />} />
        <Route path='/add-client' element={<AddClient />} />
        {/* Dealer Management System */}
        <Route path='/dealer/add-dealer' element={<CreateDealer nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/dealer/list-dealer' element={<ListDealer nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/dealer/edit-dealer/:id' element={<EditDealer nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/dealer/view-dealer/:id' element={<ViewDealer nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/dealer/account-view/:id' element={<AccountView nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/dealer/edit-profile/:id' element={<Profile nav={{ isSidebar, setIsSidebar }} />} />
        {/* Order management System */}
        <Route path='/order/list' element={<ListOrder nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/order/add-order' element={<AddOrder nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/order/order-detail/:id' element={<DetailOrder nav={{ isSidebar, setIsSidebar }} />} />
        {/* Analytics */}
        <Route path='/analytics/annual-sales' element={<SalesAnalytics nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/analytics/productions' element={<ProductionAnalytics nav={{ isSidebar, setIsSidebar }} />} />
        {/* Inventory management system */}
        <Route path='/inventory/inventory-list' element={<ListInventory />} />
        {/* Product management system */}
        <Route path='/product-management/add-product-attributes' element={<AddProductAttribute nav={{ isSidebar, setIsSidebar }} />} />
        {/* Applications */}
        <Route path='/app/invoice-generator' element={<InvoiceApp nav={{ isSidebar, setIsSidebar }} />} />
        //------------------------  PUBLIC ROUTES  ------------------
        <Route path='/public/order' element={<OrderPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App