import { OrderTableHeader, OrderTableValues } from "./tables/OrderTableUI"

const OrderTableView = ({ o, isView, isEditView }) => {
    return (
        <table
            id="example"
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
        >
            <>
                <OrderTableHeader
                    productType={o.productType}
                    isEdit={false}
                />
                <tbody>
                    {o.orderArray.map((order, index) => (
                        <>
                            <tr>
                                <td >{index + 1}</td>
                                <OrderTableValues o={order} productType={o.productType} isEdit={false} />
                                {/* <td >
                                    <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                        <button
                                            // onClick={(e) => handleEditClick(e, o)}
                                            className="text-warning bg-dark" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="Edit">
                                            <i className="bi bi-pencil-fill"></i>
                                        </button>
                                        <a href="javascript:;" className="text-primary" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="Views"><i className="bi bi-eye-fill"></i>
                                        </a>
                                        <button
                                            // onClick={() => handleDeleteData(o.id)}
                                            className="text-danger bg-dark" data-bs-toggle="tooltip"
                                            data-bs-placement="bottom" title="Delete"><i className="bi bi-trash-fill"></i>
                                        </button>
                                    </div>
                                </td> */}
                            </tr>
                        </>
                    ))}
                </tbody>
            </>
        </table>
    )
}

export default OrderTableView