import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const ProductionBarByMonth = () => {

    const data = {
        labels: ['Jan', 'Feb', 'Mar', "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"],
        datasets: [
            //Bar-1 Data set
            {
                label: 'Roller Shades',
                data: [4, 6, 4],
                backgroundColor: 'aqua',
                borderColor: 'black',
                borderWidth: 1
            },
            //Bar-2 Data set
            {
                label: 'Zebra Blinds',
                data: [8, 3, 5],
                backgroundColor: 'green',
                borderColor: 'black',
                borderWidth: 1
            },
            {
                label: 'Zebra Blinds',
                data: [8, 3, 5],
                backgroundColor: 'red',
                borderColor: 'black',
                borderWidth: 1
            },
            {
                label: 'Material/Other',
                data: [8, 3, 5],
                backgroundColor: 'orange',
                borderColor: 'black',
                borderWidth: 1
            }
        ]
    }

    const options = {

    }

    return (
        <>
            <Bar
                data={data}
                options={options}
            />
        </>
    )
}

export default ProductionBarByMonth