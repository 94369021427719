import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { AppointDetailAction, DeleteAppointAction, UpdateAppointAction } from '../../redux/actions/AppointActions'

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Loader from '../layout/Loader';
import Badge from '../layout/Badge';
import { Link } from 'react-router-dom';

const Edit = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    //getting the details
    const detail = useSelector(state => state.appointDetail)
    const { loading, appoint, success: detailSuccess, error } = detail

    const updateAppoint = useSelector((state) => state.appointUpdate)
    const { loading: updateLoading, success: updateSuccess } = updateAppoint

    const deleteAppoint = useSelector((state) => state.appointDelete)
    const { loading: deleting, success: deleteSuccess } = deleteAppoint

    const [name, setName] = useState()
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [des, setDes] = useState('');
    const [timing, setTiming] = useState('');
    const [action, setAction] = useState('');
    const [actionStatus, setActionStatus] = useState(false);
    const [payment, setPayment] = useState(0.00)
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [completion, setCompletion] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (timing !== appoint.timing) {
            setTiming(timing.$d)
        }
        dispatch(UpdateAppointAction({
            id: appoint._id,
            name,
            email,
            phone,
            address,
            des,
            timing,
            action,
            actionStatus,
            payment,
            paymentStatus,
            completion
        }))
        // if (updateSuccess) {
        //     navigate('/appointments')
        // }
    }

    const handleDelete = (event) => {
        event.preventDefault();
        dispatch(DeleteAppointAction(id))
        // if (deleteSuccess) {
        //     navigate('/')
        // }
    }

    useEffect(() => {
        dispatch({ type: 'APPOINT_UPDATE_RESET' })
        dispatch(AppointDetailAction(id))
        if (detailSuccess) {
            setName(appoint.name)
            setEmail(appoint.email)
            setPhone(appoint.phone)
            setAddress(appoint.address)
            setDes(appoint.des)
            setTiming(appoint.timing)
            setAction(appoint.action)
            setActionStatus(appoint.actionStatus)
            setPayment(appoint.payment)
            setPaymentStatus(appoint.paymentStatus)
            setCompletion(appoint.completion)
        }
    }, [id]);

    console.log(updateSuccess, deleteSuccess)

    return (
        <>
            {/* {success && <Badge p={{ type: 'success', message: 'New Appointment has been created' }} />} */}

            {updateSuccess || deleteSuccess ? (
                <Badge p={{ type: 'success', message: 'Action completed successfully👍' }} />
            ) : (loading || updateLoading || deleting ? (<Loader />) : (
                detailSuccess &&
                <>
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Dashboard</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/appointments">
                                            <i className="bx bx-calendar-event" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Edit Appointment
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="ms-auto">
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">
                                    Settings
                                </button>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                                    {" "}
                                    <button type="button"
                                        className="btn btn-danger px-5"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <form onSubmit={handleSubmit}>
                            <div className="col-lg-12 mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-8">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-6">
                                                                <label className="form-label"> Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Client Name"
                                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label"> email</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Client email"
                                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label">Phone</label>
                                                                <input
                                                                    type="telephone"
                                                                    className="form-control"
                                                                    placeholder="Client Phone"
                                                                    value={phone} onChange={(e) => setPhone(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-8">
                                                                <label className="form-label">Address</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Client address"
                                                                    value={address} onChange={(e) => setAddress(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="col-12">
                                                                <label className="form-label">Full description</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Full description"
                                                                    rows={6}
                                                                    cols={4}
                                                                    defaultValue={""}
                                                                    value={des} onChange={(e) => setDes(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-12">
                                                                <div className="bg-secondary p-2">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DateTimePicker
                                                                            renderInput={(props) => <TextField {...props} />}
                                                                            label="DateTimePicker"
                                                                            value={timing}
                                                                            onChange={(newValue) => {
                                                                                setTiming(newValue);
                                                                            }}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Action</label>
                                                                <select className="form-select"
                                                                    value={action} onChange={(e) => setAction(e.target.value)}>
                                                                    <option value={action}>{action}</option>
                                                                    {
                                                                        (() => {
                                                                            switch (action) {
                                                                                case 'General-Inquiry':
                                                                                    return (
                                                                                        <>
                                                                                            <option value='Quotation'>Quotation</option>
                                                                                            <option value='Measurement'>Measurement</option>
                                                                                            <option value='Production'>Production</option>
                                                                                            <option value='Installation'>Installation</option>
                                                                                            <option value='Repair/Inspection'>Repair/Inspection</option>
                                                                                            <option value=''>--Reset--</option>
                                                                                        </>
                                                                                    )
                                                                                case 'Quotation':
                                                                                    return (
                                                                                        <>
                                                                                            <option value='Measurement'>Measurement</option>
                                                                                            <option value='Production'>Production</option>
                                                                                            <option value='Installation'>Installation</option>
                                                                                            <option value='Repair/Inspection'>Repair/Inspection</option>
                                                                                            <option value=''>--Reset--</option>
                                                                                        </>
                                                                                    )
                                                                                case 'Measurement':
                                                                                    return (
                                                                                        <>
                                                                                            <option value='Quotation'>Quotation</option>
                                                                                            <option value='Production'>Production</option>
                                                                                            <option value='Installation'>Installation</option>
                                                                                            <option value=''>--Reset--</option>
                                                                                        </>
                                                                                    )
                                                                                case 'Production':
                                                                                    return (
                                                                                        <>
                                                                                            <option value='Installation'>Installation</option>
                                                                                            <option value=''>--Reset--</option>
                                                                                        </>
                                                                                    )
                                                                                case 'Installation':
                                                                                    return (
                                                                                        <>
                                                                                            <option value='Production'>Production</option>
                                                                                            <option value='Repair/Inspection'>Repair/Inspection</option>
                                                                                            <option value=''>--Reset--</option>
                                                                                        </>
                                                                                    )
                                                                                case 'Repair/Inspection':
                                                                                    return (
                                                                                        <>
                                                                                            <option value='Production'>Production</option>
                                                                                            <option value='Installation'>Installation</option>
                                                                                            <option value=''>--Reset--</option>
                                                                                        </>
                                                                                    )

                                                                                default:
                                                                                    return (
                                                                                        <>
                                                                                            <option value='General-Inquiry'>General Inquiry</option>
                                                                                            <option value='Quotation'>Quotation</option>
                                                                                            <option value='Measurement'>Measurement</option>
                                                                                            <option value='Production'>Production</option>
                                                                                            <option value='Installation'>Installation</option>
                                                                                            <option value='Repair/Inspection'>Repair/Inspection</option>
                                                                                        </>
                                                                                    )
                                                                            }
                                                                        })()
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Action Status</label>
                                                                <select className="form-select"
                                                                    value={actionStatus} onChange={(e) => setActionStatus(e.target.value)}>
                                                                    <option></option>
                                                                    <option value={false}>Pending</option>
                                                                    <option value={true}>Completed</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Payment</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Payment"
                                                                    pattern="^\d*(\.\d{0,2})?$"
                                                                    value={payment} onChange={(e) => setPayment(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Payment Status</label>
                                                                <select className="form-select"
                                                                    value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                                                                    <option></option>
                                                                    <option value={false}>Pending</option>
                                                                    <option value={true}>Paid</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                action.status == true && (
                                                                    <div className="col-12">
                                                                        <label className="form-label">Project Status</label>
                                                                        <select className="form-select"
                                                                            value={completion} onChange={(e) => setCompletion(e.target.value)}>
                                                                            <option></option>
                                                                            <option value={false}> Project Pending</option>
                                                                            <option value={true}>Project Completed</option>
                                                                        </select>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        {/*end row*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end row*/}
                                    </div>
                                    <div className="card-header py-3 bg-transparent">
                                        <div className="d-sm-flex align-items-center">
                                            <h5 className="mb-2 mb-sm-0">Edit Project</h5>
                                            <div className="ms-auto ">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onSubmit={handleSubmit}
                                                >
                                                    Update Project
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            ))}

        </>
    )
}

export default Edit