import { Link } from 'react-router-dom'

const Badge = ({ p }) => {
    return (
        <div className={`alert border-0 bg-light-${p.type} alert-dismissible fade show py-2`}>
            <div className="d-flex align-items-center">
                <div className={`fs-3 text-${p.type}`}>
                    <i className="bi bi-check-circle-fill" />
                </div>
                <div className="ms-3">
                    <div className={`text-${p.type}`}>{p.message}</div>
                </div>
                {p.redirect ? (
                    p.refresh ? (
                        <a href={`${p.redirect}`} className="px-5 text-light">➡ {p.redirectName}</a>
                    ) : (
                        <Link to={`${p.redirect}`} className="px-5 text-light">➡ {p.redirectName}</Link>
                    )
                ) : (
                    p.refresh ? (
                        <a href="/" className="px-5 text-light">➡ Dashboard</a>
                    ) : (
                        <Link to="/" className="px-5 text-light">➡ Dashboard</Link>
                    )
                )}

            </div>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
            />
        </div>

    )
}

export default Badge