import React, { useEffect } from 'react'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import Detail from '../../components/appointment/Detail'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AppointDetailAction } from '../../redux/actions/AppointActions'
import Loader from '../../components/layout/Loader'

const DetailAppointment = ({ nav }) => {

    const dispatch = useDispatch()
    const { id } = useParams()

    const detail = useSelector(state => state.appointDetail)
    const { loading, appoint, success, error } = detail

    useEffect(() => {
        dispatch(AppointDetailAction(id))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {success ? (<Detail p={appoint} />) : (<Loader />)}
            </div>
        </>
    )
}

export default DetailAppointment