import React from 'react'
import { Link } from 'react-router-dom'
import ActionCreate from '../../components/actions/ActionCreate'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'

const AddAction = ({ nav }) => {
    return (
        <>
            <>
                <div className={nav.isSidebar}>
                    <Header nav={nav} />
                    <Sidebar nav={nav} />
                    {/*start content*/}
                    <main className="page-content">
                        {/*breadcrumb*/}
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="breadcrumb-title pe-3">Dashboard</div>
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="bx bx-home-alt" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Create An Action
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        {/*end breadcrumb*/}
                        <ActionCreate />
                        {/*end row*/}
                    </main>

                    <div className="overlay nav-toggle-icon" />
                    <a href="" className="back-to-top">
                        <i className="bx bxs-up-arrow-alt" />
                    </a>
                </div>
            </>
        </>
    )
}

export default AddAction