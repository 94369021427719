import React from 'react'
import { Link } from 'react-router-dom'
import DealersDoughnut from '../../../components/analytics/sales/DealersDoughnut'
import LineChart from '../../../components/analytics/sales/LineChart'
import Header from '../../../components/layout/Header'
import Sidebar from '../../../components/layout/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AnnualSaleAnalAction } from '../../../redux/actions/AnalyticsActions'
import Loader from '../../../components/layout/Loader'
import LoaderLin from '../../../components/layout/LoaderLin'
import { ListDealerAction } from '../../../redux/actions/DealerAction'
import { useState } from 'react'

const SalesAnalytics = ({ nav }) => {
    const dispatch = useDispatch()
    const annualSaleAnal = useSelector(state => state.annualSaleAnal)
    const { loading, data, success } = annualSaleAnal
    const listDealer = useSelector(state => state.listDealer)
    const { loading: loadingDealer, dealer, successDealer } = listDealer
    // console.log("🚀 ~ file: SalesAnalytics.js:21 ~ SalesAnalytics ~ dealer:", dealer)

    const [dealerName, setDealerName] = useState();



    useEffect(() => {
        dispatch(ListDealerAction())
        dispatch(AnnualSaleAnalAction({ companyName: dealerName }))
    }, [dealerName]);


    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {/*start content*/}
                <main className="page-content">
                    {/*breadcrumb*/}
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Dashboard</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <i className="bx bx-home-alt" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Sales Analytics
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <hr />
                    <div className="row ">
                        <div className="col-xl-12 mx-auto ">
                            <div className='row'>
                                <div className="col-md-8 col-lg-8 col-xl-8">
                                    <h6 className="mb-0 "><i className="fadeIn animated bx bx-line-chart" /> Annual sales sort by dealer - Line Graph</h6>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xl-4 mb-2">
                                    {/* <label className="form-label"> Company Name</label> */}
                                    {
                                        loadingDealer ? (<LoaderLin />) : (

                                            <select className="form-select"
                                                value={dealerName}
                                                onChange={(e) => setDealerName(e.target.value)}
                                            >
                                                {!dealerName && <option>Inhouse</option>}
                                                {dealer && dealer.map((c) => (
                                                    <option value={c._id}>{c.companyName}</option>
                                                ))}
                                            </select>)
                                    }
                                </div>
                            </div>
                            {!data ? (<Loader />) : (<LineChart analData={data} />)}
                        </div>
                    </div>
                    <hr />
                    {/* <DealersDoughnut /> */}
                    {/*end row*/}
                </main>

                <div className="overlay nav-toggle-icon" />
                <a href="" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt" />
                </a>
            </div>
        </>
    )
}

export default SalesAnalytics