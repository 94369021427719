import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ListDealerAction } from '../../redux/actions/DealerAction'


const ListDealerTable = () => {
    const dispatch = useDispatch()

    const list = useSelector((state) => state.listDealer)
    const { loading, success, dealer } = list

    useEffect(() => {
        dispatch(ListDealerAction())
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0">Customer Details</h5>
                        <form className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-search" />
                            </div>
                            <input className="form-control ps-5" type="text" placeholder="search" />
                        </form>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table align-middle">
                            <thead className="table-secondary">
                                <tr>
                                    <th>Company</th>
                                    <th>Name</th>
                                    <th>Phone#</th>
                                    <th>email</th>
                                </tr>
                            </thead>
                            {dealer && dealer.map((d) =>
                                <>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center cursor-pointer">
                                                    <div className="">
                                                        <Link to={`/dealer/view-dealer/${d._id}`}>
                                                            <p className="mb-0">
                                                                {d.companyName}
                                                            </p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {d.name}
                                            </td>
                                            <td><a href="tel:PHONE_NUM">
                                                {d.phone}
                                            </a></td>
                                            <td>{d.email}</td>
                                            {/* <td></td> */}
                                        </tr>
                                    </tbody>
                                </>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListDealerTable