import { nanoid } from "nanoid"
import { fauxwoodProduction } from "../../../functions/productionSheet/fauxwood/FauxwoodProduction"
import { cvrtDeci2Frac, totalDecimal } from "../../../functions/productionSheet/GolbalFunctions"
import { rollerEquation } from "../../../functions/productionSheet/rollerShades/RollerProduction"

//| ---------------------UPDATE------------------------

// This update single order inside Arr by finding the index
export const updateOrderArr = ({ OrderArr, editProductId, productType, widthEdit, heightEdit, remarkEdit, headrail }) => {
    const newOrder = [...OrderArr]
    const index = OrderArr.findIndex((o) => o.id === editProductId)
    switch (productType) {
        case 'Zebra_blinds':
        case 'Roller_shades':
            const { fascia, tube, fabric, sqft } = rollerEquation(widthEdit, heightEdit, headrail)
            newOrder[index] = {
                id: editProductId,
                width: widthEdit,
                height: heightEdit,
                // control: controlEdit,
                // lift: liftEdit,
                // mount: mountEdit,
                remark: remarkEdit,
                fascia, tube, fabric, factor: sqft
            }
            // setOrder(newOrder)
            // seteditProductId(null)
            return newOrder
            break

        case 'Fauxwood':
            const { readyWidth, slat, fullPrice } = fauxwoodProduction(widthEdit, heightEdit)
            newOrder[index] = {
                id: editProductId,
                width: widthEdit,
                height: heightEdit,
                // control: controlEdit,
                // lift: liftEdit,
                // mount: mountEdit,
                remark: remarkEdit,
                readyWidth, slat, factor: fullPrice
            }
            // setOrder(newOrder)
            // seteditProductId(null)
            return newOrder
            break

        default:
            return null
    }
}

// Update the overall order factor
export const updateOrderFactor = (orderArr) => {
    return (orderArr.reduce((n, { factor }) => n + factor, 0))
}

// Delete the order by id from order Arr
export const deleteOrderbyId = (orderId, orderArr) => {
    const newOrder = [...orderArr]
    const index = orderArr.findIndex((o) => o.id === orderId)
    newOrder.splice(index, 1);
    console.log(newOrder)
    return (newOrder)
}

//* -------------------Order Calculation-------------------
export function getProductionData(productType, width, height, remark, headrail) {
    if (productType === 'Fauxwood') {
        const { readyWidth, slat, fullPrice } = fauxwoodProduction(width, height)
        const newOrder = {
            id: nanoid(),
            width, height, remark,
            readyWidth, slat, factor: fullPrice
        };
        return newOrder
        // const updateOrder = [...order, newOrder];
        // setOrder(updateOrder);
    } else if (productType === 'Zebra_blinds' || 'Roller_shades') {
        const { fascia, tube, fabric, sqft } = rollerEquation(width, height, headrail)
        const newOrder = {
            id: nanoid(),
            width, height, remark,
            fascia, tube, fabric, factor: sqft
        };
        return newOrder
        // const updateOrder = [...order, newOrder];
        // setOrder(updateOrder);
    }
}

//+ -----------------Attributes:Order Attributes Functions---------------
//This function will split the left,right and middle splits and returns the l,r,m values
export function splitValidation(width, name, value, setSplitWidth, splitWidth) {
    var decWidth = totalDecimal(width)
    if (name === 'l') {
        setSplitWidth({ ...splitWidth, l: value, r: cvrtDeci2Frac(decWidth - totalDecimal(value)), m: 0 })
    } else if (name === 'r') {
        var right = totalDecimal(value)
        setSplitWidth({ ...splitWidth, r: value, m: cvrtDeci2Frac(decWidth - (right + totalDecimal(splitWidth.l))) })
    }
}