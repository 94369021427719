import { Alert, Box, Modal, Stack } from '@mui/material'
import React, { useState } from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60vw",
    height: "50vh",
    // bgcolor: '#16223b',
    // border: '2px solid #373737',
    // boxShadow: 24,
    pt: 1,
    px: 1,
    pb: 3,
};


export function ResetPwdModel(open, setOpen) {

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: ''
    });
    const [message, setMessage] = useState({
        state: false,
        message: ''
    });

    const handleClose = () => {
        setOpen(false);
        setMessage({
            state: false,
            message: ''
        })
        setPassword({})
    };

    const updatePassword = () => {
        if (password.password === password.confirmPassword) {

        } else {
            setMessage({
                state: true,
                message: 'Password dose not match 😬'
            })
        }
    }

    return (
        <>
            <Modal
                open={open}>
                <Box sx={{ ...style }}>

                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card shadow-sm border-0">
                                <div className="card-body">
                                    <h5 className="mb-0">Update Password</h5>
                                    <hr />
                                    <div className="card shadow-none border">
                                        <div className="card-header">
                                            <h6 className="mb-0">Enter the new password</h6>
                                        </div>
                                        <div className="card-body">
                                            {message.state && (
                                                <p className='text-danger'>{message.message}</p>
                                            )}
                                            <form className="row g-3">
                                                <div className="col-6">
                                                    <label className="form-label">New password*</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        required
                                                        value={password.password}
                                                        onChange={(e) => setPassword({
                                                            password: e.target.value
                                                        })}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Confirm password*</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        value={password.confirmPassword}
                                                        onChange={(e) => setPassword({
                                                            confirmPassword: e.target.value
                                                        })}
                                                        required
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card-header py-1 bg-transparent">
                                        <div className="d-sm-flex align-items-center">
                                            <div className="ms-auto">
                                                <button type="submit "
                                                    className="btn btn-secondary mx-2"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onClick={updatePassword}
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal >
        </>
    )
}
