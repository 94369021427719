import Loader from "../layout/Loader"
import LoaderLin from "../layout/LoaderLin"

const ViewDealerTable = ({ d }) => {

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mx-auto">
                    <div className="card">
                        <div className="card-body mt-3">
                            <h5>Dealer Info</h5>
                            {d.detailSuccess ? (
                                <>
                                    <div className="row row-cols-1 row-cols-xl-2 row-cols-xxl-3">
                                        <div className="col">
                                            <div className="card border shadow-none radius-10">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="icon-box bg-light-primary border-0">
                                                            <i className="bi bi-person text-primary" />
                                                        </div>
                                                        <div className="info">
                                                            <h6 className="mb-2">Dealer Info</h6>
                                                            <p className="mb-1"><strong>Company</strong>
                                                                &nbsp;:&nbsp;{d.dealer.companyName}</p>
                                                            <p className="mb-1"><strong>Owner</strong>
                                                                &nbsp;:&nbsp;{d.dealer.name}</p>
                                                            <p className="mb-1"><strong> Type</strong>
                                                                &nbsp;:&nbsp;Wholesaler  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card border shadow-none radius-10">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="icon-box bg-light-warning border-0">
                                                            <i className="bi bi-geo-alt text-warning" />
                                                        </div>
                                                        <div className="info">
                                                            <h6 className="mb-2">Contact Details</h6>
                                                            <p className="mb-1">
                                                                <strong>Address</strong>
                                                                &nbsp;:&nbsp;{d.dealer.address}
                                                            </p>
                                                            <p className="mb-1">
                                                                <strong>eMail</strong>
                                                                &nbsp;:&nbsp;{d.dealer.email}
                                                            </p>
                                                            <p className="mb-1">
                                                                <strong>Phone</strong>
                                                                &nbsp;:&nbsp;{d.dealer.phone}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card border shadow-none radius-10">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="icon-box bg-light-success border-0">
                                                            <i className="bi bi-box text-success" />
                                                        </div>
                                                        <div className="info">
                                                            <h6 className="mb-2">More Info </h6>
                                                            <p className="mb-1">
                                                                Rates Management
                                                            </p>
                                                            <p className="mb-1">
                                                                Order Management
                                                            </p>
                                                            <p className="mb-1">
                                                                Account Management
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (<Loader />)}

                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-4">
                                <div className="col">
                                    <div className="card radius-10 bg-primary">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <p className="mb-1 text-white">Current Month Orders</p>
                                                    {d.statsSuccess ? (
                                                        <><h4 className="mb-0 text-white">{d.stats.ttlOrder}</h4></>) :
                                                        <LoaderLin />
                                                    }
                                                </div>
                                                <div className="ms-auto widget-icon bg-white-1 text-white">
                                                    <i className="bi bi-bag-check-fill" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card radius-10 bg-success">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <p className="mb-1 text-white">Account Payment</p>
                                                    {d.statsSuccess ? (
                                                        <><h4 className="mb-0 text-white">{d.stats.unPaidPayment}</h4></>) :
                                                        <LoaderLin />
                                                    }
                                                </div>
                                                <div className="ms-auto widget-icon bg-white-1 text-white">
                                                    <i className="bi bi-currency-dollar" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card radius-10 bg-pink">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <p className="mb-1 text-white">#Unpaid Orders</p>
                                                    {d.statsSuccess ? (
                                                        <><h4 className="mb-0 text-white">{d.stats.unPaidOrders}</h4></>) :
                                                        <LoaderLin />
                                                    }
                                                </div>
                                                <div className="ms-auto widget-icon bg-white-1 text-white">
                                                    <i className="bi bi-bar-chart-fill" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card radius-10 bg-orange">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <p className="mb-1 text-white">#Orders on process</p>
                                                    {d.statsSuccess ? (
                                                        <><h4 className="mb-0 text-white">{d.stats.ordersInProcess}</h4></>) :
                                                        <LoaderLin />
                                                    }
                                                </div>
                                                <div className="ms-auto widget-icon bg-white-1 text-white">
                                                    <i className="bi bi-person-plus-fill" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ))} */}
        </>
    )
}

export default ViewDealerTable