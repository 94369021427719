import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export function MUIDropdown({ toggle, title, actions }) {
    const open = Boolean(toggle.dropdownToggle);
    const handleClick = (event) => {
        toggle.setDropdownToggle(event.currentTarget);
    };
    const handleClose = () => {
        toggle.setDropdownToggle(null);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                variant="contained"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
            >
                {title}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={toggle.dropdownToggle}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {actions}
            </Menu>
        </div>
    );
}

export function test() {

}
