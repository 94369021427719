import React, { useEffect } from 'react'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import ClientListTable from '../../components/clients/ClientListTable'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ListClientAction } from '../../redux/actions/ClientAction'
import Loader from '../../components/layout/Loader'

const ClientList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const listClients = useSelector(state => state.clientList)
    const { loading, clients, success } = listClients

    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
    }, [userInfo, navigate])

    useEffect(() => {
        dispatch(ListClientAction())

    }, []);

    // console.log(clients)

    return (
        <>
            <div className="wrapper ">
                <Header />
                <Sidebar />
                {/*start content*/}
                <main className="page-content">
                    {
                        loading ? (
                            <Loader />
                        ) : (
                            <ClientListTable p={clients} />
                        )
                    }
                </main>
                <div className="overlay nav-toggle-icon" />
                <a href="" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt" />
                </a>
            </div>
        </>
    )
}

export default ClientList