export const APPOINT_LIST_REQUEST = 'APPOINT_LIST_REQUEST'
export const APPOINT_LIST_SUCCESS = 'APPOINT_LIST_SUCCESS'
export const APPOINT_LIST_FAIL = 'APPOINT_LIST_FAIL'

export const TASK_LIST_REQUEST = 'TASK_LIST_REQUEST'
export const TASK_LIST_SUCCESS = 'TASK_LIST_SUCCESS'
export const TASK_LIST_FAIL = 'TASK_LIST_FAIL'

export const P_PENDING_LIST_REQUEST = 'P_PENDING_LIST_REQUEST'
export const P_PENDING_LIST_SUCCESS = 'P_PENDING_LIST_SUCCESS'
export const P_PENDING_LIST_FAIL = 'P_PENDING_LIST_FAIL'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const APPOINT_CREATE_REQUEST = 'APPOINT_CREATE_REQUEST'
export const APPOINT_CREATE_SUCCESS = 'APPOINT_CREATE_SUCCESS'
export const APPOINT_CREATE_FAIL = 'APPOINT_CREATE_FAIL'

export const APPOINT_DETAIL_REQUEST = 'APPOINT_DETAIL_REQUEST'
export const APPOINT_DETAIL_SUCCESS = 'APPOINT_DETAIL_SUCCESS'
export const APPOINT_DETAIL_FAIL = 'APPOINT_DETAIL_FAIL'

export const APPOINT_UPDATE_REQUEST = 'APPOINT_UPDATE_REQUEST'
export const APPOINT_UPDATE_SUCCESS = 'APPOINT_UPDATE_SUCCESS'
export const APPOINT_UPDATE_FAIL = 'APPOINT_UPDATE_FAIL'

export const APPOINT_DELETE_REQUEST = 'APPOINT_DELETE_REQUEST'
export const APPOINT_DELETE_SUCCESS = 'APPOINT_DELETE_SUCCESS'
export const APPOINT_DELETE_FAIL = 'APPOINT_DELETE_FAIL'