import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateClientAction } from '../../redux/actions/ClientAction'
import Badge from '../layout/Badge'
import Loader from '../layout/Loader'

const AddClientForm = () => {
    const addClient = useSelector((state) => state.createClient)
    const { loading, success: createdSuccess } = addClient

    const dispatch = useDispatch()

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState('');
    const [des, setDes] = useState('');


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = {
            name,
            email,
            phone,
            address,
            des
        }
        dispatch(CreateClientAction(form))
    }

    useEffect(() => {
        if (createdSuccess) {
            setName('');
            setEmail('');
            setPhone();
            setAddress('');
            setDes('');
        }
    }, [createdSuccess]);

    return (
        <>
            {createdSuccess && <Badge p={{ type: 'success', message: 'New Appointment has been created' }} />}
            {loading ? (<Loader />) : (
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="card shadow-sm border-0">
                            <div className="card-body">
                                <h5 className="mb-0">Add Client</h5>
                                <hr />
                                <div className="card shadow-none border">
                                    <div className="card-header">
                                        <h6 className="mb-0">CLIENT INFORMATION</h6>
                                    </div>
                                    <div className="card-body">
                                        <form className="row g-3" onSubmit={handleSubmit}>
                                            <div className="col-lg-6">
                                                <label className="form-label">Full Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Email address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue=""
                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Phone Number</label>
                                                <input
                                                    className="form-control"
                                                    type="tel" id="phone" name="phone"
                                                    value={phone} onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={address} onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Note about the client</label>
                                                <textarea
                                                    className="form-control"
                                                    rows={4}
                                                    cols={4}
                                                    placeholder="Client notes here..."
                                                    value={des} onChange={(e) => setDes(e.target.value)}
                                                />
                                            </div>
                                            <div className="text-start">
                                                <button type="submit"
                                                    onSubmit={handleSubmit}
                                                    className="btn btn-primary px-4">
                                                    Add Client
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            )}
        </>
    )
}

export default AddClientForm