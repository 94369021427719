import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Loader from '../layout/Loader'
import { CreateAppointAction } from '../../redux/actions/AppointActions';
import Badge from '../layout/Badge';


const CreateAppointment = () => {
    const newAppoint = useSelector((state) => state.appointCreate)
    const { loading, success } = newAppoint

    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [des, setDes] = useState('')
    const [actionStatus, setActionStatus] = useState(false);
    const [action, setAction] = useState('')
    const [payment, setPayment] = useState(0.00)
    const [timing, setTiming] = useState(dayjs());

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = {
            name,
            email,
            phone,
            address,
            des,
            timing: timing.$d,
            action,
            payment,
        }
        dispatch(CreateAppointAction(form))
    }

    useEffect(() => {

    }, []);

    // console.log(timing)
    // console.log(timing.$d)

    return (
        <>
            {success && <Badge p={{ type: 'success', message: 'New Appointment has been created' }} />}
            {loading ? (<Loader />) : (
                <div className="row">
                    <form onSubmit={handleSubmit}>
                        <div className="col-lg-12 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row g-3">
                                        <div className="col-12 col-lg-8">
                                            <div className="card shadow-none bg-light border">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-6">
                                                            <label className="form-label"> Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Client Name"
                                                                value={name} onChange={(e) => setName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label"> email</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="Client email"
                                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-4">
                                                            <label className="form-label">Phone</label>
                                                            <input
                                                                type="telephone"
                                                                className="form-control"
                                                                placeholder="Client Phone"
                                                                value={phone} onChange={(e) => setPhone(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-8">
                                                            <label className="form-label">Address</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Client address"
                                                                value={address} onChange={(e) => setAddress(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="col-12">
                                                            <label className="form-label">Full description</label>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Full description"
                                                                rows={6}
                                                                cols={4}
                                                                defaultValue={""}
                                                                value={des} onChange={(e) => setDes(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="card shadow-none bg-light border">
                                                <div className="card-body">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <div className="bg-secondary p-2">
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateTimePicker
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        label="DateTimePicker"
                                                                        value={timing}
                                                                        onChange={(newValue) => {
                                                                            setTiming(newValue);
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label">Action</label>
                                                            <select className="form-select"
                                                                value={action} onChange={(e) => setAction(e.target.value)}>
                                                                <option></option>
                                                                <option value='Measurement'>Measurement</option>
                                                                <option value='Production'>Production</option>
                                                                <option value='Installation'>Installation</option>
                                                                <option value='Repair/Inspection'>Repair/Inspection</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label">Payment</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Payment"
                                                                pattern="^\d*(\.\d{0,2})?$"
                                                                value={payment} onChange={(e) => setPayment(e.target.value)}
                                                            />
                                                        </div>
                                                        {/* <div className="col-12">
                                                        <label className="form-label">Payment Status</label>
                                                        <select className="form-select"
                                                            value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                                                            <option></option>
                                                            <option>Paid</option>
                                                            <option>Pending</option>
                                                        </select>
                                                    </div> */}
                                                    </div>
                                                    {/*end row*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*end row*/}
                                </div>
                                <div className="card-header py-3 bg-transparent">
                                    <div className="d-sm-flex align-items-center">
                                        <h5 className="mb-2 mb-sm-0">Create New Appointment</h5>
                                        <div className="ms-auto">
                                            <button type="submit"
                                                className="btn btn-primary"
                                                onSubmit={handleSubmit}>
                                                Create Appointment
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    )
}

export default CreateAppointment