import React from 'react'

const PercentageBar = () => {
    return (
        <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 d-flex">
                <div className="card radius-10 w-100">
                    <div className="card-header bg-transparent">
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h5 className="mb-0">Top Categories</h5>
                            </div>
                            <div className="col">
                                <div className="d-flex align-items-center justify-content-end gap-3 cursor-pointer">
                                    <div className="dropdown">
                                        <a
                                            className="dropdown-toggle dropdown-toggle-nocaret"
                                            href="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="bx bx-dots-horizontal-rounded font-22 text-option" />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="javascript:;">
                                                    Action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="javascript:;">
                                                    Another action
                                                </a>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="javascript:;">
                                                    Something else here
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="categories">
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Electronic <span className="float-end">85%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-purple"
                                        role="progressbar"
                                        style={{ width: "85%" }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 border-top" />
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Furniture <span className="float-end">70%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-danger"
                                        role="progressbar"
                                        style={{ width: "70%" }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 border-top" />
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Fashion <span className="float-end">66%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-success"
                                        role="progressbar"
                                        style={{ width: "2%" }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 border-top" />
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Mobiles <span className="float-end">76%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-info"
                                        role="progressbar"
                                        style={{ width: "76%" }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 border-top" />
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Accessories <span className="float-end">80%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-warning"
                                        role="progressbar"
                                        style={{ width: "80%" }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 border-top" />
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Watches <span className="float-end">65%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-voilet"
                                        role="progressbar"
                                        style={{ width: "65%" }}
                                    />
                                </div>
                            </div>
                            <div className="my-3 border-top" />
                            <div className="progress-wrapper">
                                <p className="mb-2">
                                    Sports <span className="float-end">45%</span>
                                </p>
                                <div className="progress" style={{ height: 6 }}>
                                    <div
                                        className="progress-bar bg-gradient-royal"
                                        role="progressbar"
                                        style={{ width: "45%" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PercentageBar