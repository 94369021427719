import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Overview from '../components/analytics/cards/Overview'
import Header from '../components/layout/Header'
import Loader from '../components/layout/Loader'
import LoaderLin from '../components/layout/LoaderLin'
import Sidebar from '../components/layout/Sidebar'
import AppointmentsTable from '../components/tables/AppointmentsTable'
import { ListAppointAction, ListCompletedTaskAction, listGeneralInquiriesAction, ListOrderAction, ListPaymentPendingAction, listQuotationAction } from '../redux/actions/AppointActions'

const Home = ({ nav }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const listAppointment = useSelector(state => state.listAppointment)
    const { loading, appoints, success } = listAppointment

    const listOrder = useSelector(state => state.listOrder)
    const { loading: loadingOrders, orders, success: orderSuccess } = listOrder

    const completedTask = useSelector(state => state.listCompletedTask)
    const { loading: loadingTask, task, success: taskSuccess } = completedTask

    const paymentPending = useSelector(state => state.ListPaymentPending)
    const { loading: LoadingPending, orders: pOrders, success: pPendingSuccess } = paymentPending

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin

    const actionList = useSelector(state => state.ListQuotation)
    const { loading: loadingQuatation, actions } = actionList
    const inquiresList = useSelector(state => state.ListInquiries)
    const { loading: LoadingInquiries, actions: inquiries } = inquiresList

    const counter = () => {
        const appointCount = Object.keys(appoints).length
        const productionCount = Object.keys(orders).length
        const paymentPendingCount = Object.keys(pOrders).length
        const pPending = pOrders.reduce((acc, obj) => {
            return acc + obj.payment
        }, 0)
        const counts = {
            appoints: appointCount,
            production: productionCount,
            pCount: paymentPendingCount,
            pPending
        }
        return counts
    }

    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        } else {
            dispatch(ListAppointAction())
            dispatch(ListOrderAction())
            dispatch(ListCompletedTaskAction())
            dispatch(ListPaymentPendingAction())
            dispatch(listQuotationAction())
            dispatch(listGeneralInquiriesAction())
        }
    }, [userInfo, navigate])

    // console.log(counter())
    return (
        <>

            {userInfo && (
                <div className={nav.isSidebar}>
                    <Header nav={nav} />
                    <Sidebar nav={nav} />
                    {/*start content*/}
                    <main className="page-content">
                        {/* {success && <p>{console.log(counter())}</p>} */}
                        {success && orderSuccess && taskSuccess && pPendingSuccess ? (
                            <Overview counts={success && counter()} />
                        ) : (<Loader />)
                        }
                        <h5 className='pt-3'>
                            <i className="fadeIn animated bx bx-calendar" />
                            &ensp;Appointments
                        </h5>
                        {
                            loading ? (
                                <Loader />
                            ) : (
                                <AppointmentsTable p={appoints} type={'Measurement'} />
                            )
                        }
                        <br />
                        <h5 className='pt-3'>
                            <i className="fadeIn animated bx bx-file" />
                            &ensp;Quoted Orders</h5>
                        {loadingQuatation ? (
                            <Loader />
                        ) : (
                            <AppointmentsTable p={actions} type={'Quotation'} />
                        )}
                        <br />
                        <h5 className='pt-3'>
                            <i className="fadeIn animated bx bx-phone-incoming" />
                            &ensp;Inquiry</h5>
                        {loadingQuatation ? (
                            <Loader />
                        ) : (
                            <AppointmentsTable p={inquiries} type={'Inquiry'} />
                        )}
                        <br />
                        <h5 className='pt-3'>
                            <i className="fadeIn animated bx bx-dollar" />
                            &ensp;Payment Pending Orders</h5>
                        {LoadingPending ? (
                            <Loader />
                        ) : (
                            <AppointmentsTable p={pOrders} type={'Payment-pending'} />
                        )}
                        <br />
                        <h5 className='pt-3'><i className="fadeIn animated bx bx-wrench" />
                            &ensp;Orders in Production</h5>
                        {loadingOrders ? (
                            <Loader />
                        ) : (
                            <AppointmentsTable p={orders} type={'Payment-pending'} />
                        )}
                        <br />
                        <h5 className='pt-3'><i className="fadeIn animated bx bx-check-circle" />
                            &ensp;Completed Task</h5>
                        {loadingTask ? (
                            <Loader />
                        ) : (
                            <AppointmentsTable p={task} type={'Payment-pending'} />
                        )}
                    </main>
                    <div className="overlay nav-toggle-icon" />

                    <a href="" className="back-to-top">
                        <i className="bx bxs-up-arrow-alt" />
                    </a>

                </div>
            )}

        </>
    )
}

export default Home