const OrderFaq = () => {
    return (
        <div className="row mt-5">
            <div className="col col-lg-12 mx-auto mt-5">
                <div className="card radius-10">
                    <div className="card-body">
                        <h5 className="card-title">Frequent Asked Questions </h5>
                        <div className="my-3 border-top" />
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne"
                                    >
                                        How to Measure for Custom Window Blinds and Shades?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body text-white">
                                        <h6>Measuring <b>inside mount</b></h6><br />
                                        1) Measure the width inside the window opening at the top, middle and bottom. Take the narrowest of the three width measurements, and round down to the nearest 1/8".
                                        <br /><br />
                                        2) Measure the height inside the window opening from the top of the window opening to the window sill three times. Measure once on the left, at the center, and on the right and take the longest of the three measurements. If you prefer to keep the blind from resting on the sill deduct a 1/4" from the height. If your window does not have a sill, measure to the point you would like the blind to reach.
                                        <br /><br />
                                        3) Measure the window on the diagonal. If the two diagonal dimensions differ significantly, it may be necessary to order an outside mount for optimal light control and privacy. See measuring instructions below.
                                        <br /><br />
                                        4) Do not take any allowances on the height or width. The factory will take all required allowances on an inside mount for mounting and operating clearance.
                                        <br /><br /><br /><br />
                                        <h6>Measuring <b>outside mount</b></h6><br />
                                        1) Measure the distance from the outermost points you would like the blind to cover. To minimize light leakage the blind should overlap the window opening by at least 1 1/2" on each side for a total of 3" on entire width.
                                        <br /><br />
                                        2) Measure distance from where the top of the blind will be located to the window sill. The blind should overlap the window opening on the top by at least 1-1/2". Some products may require more then 1-1/2" above the window for mounting hardware. Please review product specifications on desired product for exact flat vertical surface area requirements. If flat vertical surface area exceeds 1-1/2" use specified amount to determine overlap above the window opening. If there is no window sill, measure to the point you would like the blind to reach.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo"
                                    >
                                        Need help?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body text-white">

                                        OUR MAILBOX:
                                        sales@dbsblinds.com<br />
                                        OUR PHONE:
                                        +1-855-230-5385,
                                        +1 (604) 503-5340,
                                        +1 (213) 533-2810
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree"
                                    >
                                        Want to know more about the products and product materials?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingThree"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body text-white">
                                        Log on to: <a href="https://dbsblinds.ca/services.php" target='_blank'>
                                            dbsblinds.ca/services.php
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderFaq