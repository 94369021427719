import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ResetPwdModel } from '../../components/dealer/profile/ResetPwdModel'
import Header from '../../components/layout/Header'
import Loader from '../../components/layout/Loader'
import LoaderLin from '../../components/layout/LoaderLin'
import Sidebar from '../../components/layout/Sidebar'
import { ViewDealerAction } from '../../redux/actions/DealerAction'

const Profile = ({ nav }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const dealerDetail = useSelector(state => state.viewDealer)
    const { loading, dealer, success: detailSuccess } = dealerDetail

    //Modal
    const [open, setOpen] = useState(false);

    //Modal
    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        dispatch({ type: 'DEALER_DETAIL_RESET' })
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
        if (window.innerWidth > 1200) {
            nav.setIsSidebar('wrapper toggled')
        }
    }, [userInfo, navigate])

    useEffect(() => {
        dispatch({ type: 'DEALER_DETAIL_RESET' })
        dispatch(ViewDealerAction(id))
    }, [id])


    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                <main className="page-content">
                    {/*breadcrumb*/}
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="breadcrumb-title pe-3 text-white">Pages</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript:;">
                                            <i className="bx bx-home-alt text-white" />
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        User Profile
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/*end breadcrumb*/}
                    <div className="profile-cover bg-dark" />
                    {ResetPwdModel(open, setOpen)}
                    <div className="row">
                        {!dealer ? (<Loader />) : (
                            <div className="col-12 col-lg-8">
                                <div className="card shadow-sm border-0">
                                    <div className="card-body">
                                        <h5 className="mb-0">My Account</h5>
                                        <hr />
                                        <div className="card shadow-none border">
                                            <div className="card-header">
                                                <h6 className="mb-0">USER INFORMATION</h6>
                                            </div>
                                            <div className="card-body">
                                                <form className="row g-3">
                                                    <div className="col-6">
                                                        <label className="form-label">Username</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={dealer.username}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="form-label">Email address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={dealer.email}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="card shadow-none border">
                                            <div className="card-header">
                                                <h6 className="mb-0">CONTACT INFORMATION</h6>
                                            </div>
                                            <div className="card-body">
                                                <form className="row g-3">
                                                    <div className="col-12">
                                                        <label className="form-label">Phone</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={dealer.phone}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="form-label">Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={dealer.address}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="card shadow-none border">
                                            <div className="card-header">
                                                <h6 className="mb-0">Update Setting</h6>
                                            </div>
                                            <div className="card-body">
                                                <form className="row g-3">
                                                    <div className="col-12">
                                                        <button type="button"
                                                            className="btn btn-sm btn-outline-info col-12"
                                                            onClick={handleOpen}
                                                        >
                                                            <i class="fadeIn animated bx bx-lock" />
                                                            Update Password
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border-0 overflow-hidden">
                                <div className="card-body">
                                    <div className="text-center mt-4">
                                        <div className="mt-4" />
                                        <h6 className="mb-1">
                                            {dealer ? dealer.companyName : <LoaderLin />}</h6>
                                        <p className="mb-0 text-secondary">
                                            Authorized Dealer
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="text-start">
                                        <h5 className="">Messages</h5>
                                        <p className="mb-0 text-muted">
                                            There is no messages 🤓!
                                        </p>
                                    </div>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                                        Reminder
                                        <span className="badge bg-primary rounded-pill">0</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                                        Messages
                                        <span className="badge bg-primary rounded-pill">0</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                                        Cancelation
                                        <span className="badge bg-primary rounded-pill">0</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </main>
            </div>
        </>
    )
}

export default Profile