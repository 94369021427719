import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import PrintIcon from '@mui/icons-material/Print';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import fileDownload from 'js-file-download'
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Header from '../../components/layout/Header';
import Sidebar from '../../components/layout/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { OrderByDealerIDAction } from '../../redux/actions/OrderAction';
import Loader from '../../components/layout/Loader';
import { useParams } from 'react-router';
import axios from 'axios';
import { API_URI } from '../../constants/GlobalConstants';
import { Link, useSearchParams } from 'react-router-dom';
//Dropdown Action menu
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledDropDownMenu } from '../../theme/MUI/components/StyledTheme';
import VisibilityIcon from '@mui/icons-material/Visibility';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

//|| ---------------------Title header------------------------------------
const headCells = [
    {
        id: 'orderId',
        numeric: false,
        disablePadding: true,
        label: 'Order Id',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'productType',
        numeric: false,
        disablePadding: false,
        label: 'Product Type',
    },
    {
        id: 'tag',
        numeric: false,
        disablePadding: false,
        label: 'Order Tag',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'liftStyle',
        numeric: false,
        disablePadding: false,
        label: 'Lift Style',
    },
    {
        id: 'factor',
        numeric: true,
        disablePadding: false,
        label: 'Full/SQFT',
    },
    {
        id: 'payment',
        numeric: true,
        disablePadding: false,
        label: 'Payment',
    },
    // {
    //     id: 'pending payment',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Pending',
    // },
    {
        id: 'paymentStatus',
        numeric: false,
        disablePadding: false,
        label: 'paymentStatus',
    },
    {
        id: 'View',
        numeric: false,
        disablePadding: false,
        label: 'View Order',
    },
];

//+ -----------------------Table Head-------------------------------------
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

//_ --------------------------Action Dropdown--------------------------
function EnhancedTableToolbar(props) {
    const { numSelected, orderMonth, selected, setLoading, setIsPaymentInput, renderDispatch } = props;

    //++ useState for th Action dropdown menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickAction = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //* Will be export the Production sheet PDF
    const handleActionPrint = async () => {
        try {
            const { data } = await axios.get(`${API_URI}/api/order/print-production-sheet/${selected}`, {
                responseType: 'blob',
            })
            fileDownload(data, `OrderSheet.pdf`)
        } catch (error) {
            alert('😭 Oop! Internal Error, contact the Admin🤓')
        }
    }

    //* This will update the payment status
    const handleActionPaid = async (actionStatus) => {
        setLoading(true)
        const val = {
            selected,
            paymentStatusAction: actionStatus
        }
        try {
            const { data } = await axios.put(`${API_URI}/api/order/update-payment-status`, val)
            if (data) {
                window.location.reload()
            }
        } catch (error) {
            alert('😭 Oop! Internal Error, contact the Admin🤓')
        }
    }

    //* Trigger the input UI
    const handlePaymentEdit = () => {
        setAnchorEl(null)
        setIsPaymentInput(true)
    }


    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.info.dark, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {orderMonth}
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="">
                    <div>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickAction}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            Action
                        </Button>
                        <StyledDropDownMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem sx={{ fontWeight: '500' }} disableRipple>
                                Payment Status
                            </MenuItem>
                            <MenuItem onClick={(e) => handleActionPaid(true)} disableRipple>
                                <PriceCheckIcon />
                                Save as Paid
                            </MenuItem>
                            <MenuItem onClick={(e) => handleActionPaid(false)} disableRipple>
                                <MoneyOffIcon />
                                Save as unPaid
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem sx={{ fontWeight: '500' }} disableRipple>
                                Export Order
                            </MenuItem>
                            {numSelected === 1 ? (
                                <MenuItem onClick={handleActionPrint} disableRipple>
                                    <PrintIcon />
                                    Print Order
                                </MenuItem>
                            ) : (
                                <MenuItem disabled disableRipple>
                                    <PrintIcon />
                                    Print Order
                                </MenuItem>
                            )}
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem sx={{ fontWeight: '500' }} disableRipple>
                                Payment Section
                            </MenuItem>
                            {numSelected === 1 ? (
                                <MenuItem onClick={handlePaymentEdit} disableRipple>
                                    <BorderColorIcon />
                                    Edit Payment
                                </MenuItem>
                            ) : (
                                <MenuItem disabled disableRipple>
                                    <BorderColorIcon />
                                    Edit Payment
                                </MenuItem>
                            )}
                        </StyledDropDownMenu>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        {/* <FilterListIcon /> */}
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>

    );
}

//- -------------------------Edit Payment----------------------------
function EditPayment({ selected }) {
    const [updatedPayment, setUpdatedPayment] = useState(0);
    const [loading, setLoading] = useState(false);

    const submitUpdatePayment = () => {
        setLoading(true)
        const val = {
            selected: selected[0],
            updatedPayment
        }
        axios.put(`${API_URI}/api/order//update-payment`, val)
        setLoading(false)
        window.location.reload(false)
    }
    return (
        <>
            {loading ? <Loader /> : (
                <div className="card">
                    <div className="card-body">
                        <div className="row  mb-4">
                            <div className="col-lg-8">
                                <label className="form-label"> Edit Payment  </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Upate payment"
                                    value={updatedPayment} onChange={(e) => setUpdatedPayment(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="form-group">
                                    <button className="btn btn-success w-100"
                                        type="submit"
                                        onClick={submitUpdatePayment}
                                    >
                                        Submit the updated payment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

//= ------------------------Main Table---------------------------
export default function EnhancedTable({ nav }) {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const month = searchParams.get('month') || 0
    const { id } = useParams()



    const [orderMonth, setOrderMonth] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [isPaymentInput, setIsPaymentInput] = useState(false);

    const [monthVal, setMonthVal] = useState(0);
    let renderDispatch = true
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    //++ If pagination need
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    //| Getting the selected item 
    const isSelected = (name) => selected.indexOf(name) !== -1;


    //++ If pagination
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    //_________________________MY CODE______________________________

    //+     Setting the data (row) for the MUI data array


    //getting month by month order
    const getDealerOrder = async (variables) => {
        try {
            const { data } = await axios.post(`${API_URI}/api/order/order-by-dealer?month=${month}`, variables)
            setOrderMonth(data.orderMonth)
            if (data) {
                if (!variables.loadMore) {
                    setRows(data.rows)
                    setLoading(false)
                    //| Need to load the createRowArray fun here
                } else if (variables.loadMore) {
                    setRows(data.rows)
                    setLoading(false)
                }
            } else {
                console.log("Data is not received in the FE function")
            }
        } catch (error) {
            console.log(error)
        }
    }

    //print account sheet 
    const printAccountOrderSheet = async (variables) => {
        try {
            const { data } = await axios.get(`${API_URI}/api/dealer/print-account-order-sheet?id=${id}&month=${month}`,
                {
                    responseType: 'blob',
                })
            fileDownload(data, `OrderSheet.pdf`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const variables = {
            id, monthVal, loadMore: false, month
        }
        getDealerOrder(variables)
        //Side bar will disable and results in fullscreen view
        if (window.innerWidth > 1200) {
            nav.setIsSidebar('wrapper toggled')
        }
    }, [id, month]);


    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {/*start content*/}
                <main className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Dealer Account View</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <i className="bx bx-home-alt" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/dealer/list-dealer">
                                            List All Dealers
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item " aria-current="page">
                                        <Link to={`/dealer/view-dealer/${id}`}>
                                            Dealer Overview
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Account Overview
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-8 col-lg-10">
                            <h6 className="mb-2 ">Account: Month by month </h6>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-group">
                                <button className="btn btn-outline-info w-100"
                                    type="submit"
                                    onClick={printAccountOrderSheet}
                                >
                                    <i className="fadeIn animated bx bx-printer" />Print Acc Sheet
                                </button>
                            </div>
                        </div>
                    </div>

                    {loading ? (<Loader />) : (
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <EnhancedTableToolbar numSelected={selected.length}
                                    orderMonth={orderMonth} selected={selected} setLoading={setLoading}
                                    setIsPaymentInput={setIsPaymentInput}
                                />
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={!dense ? 'small' : 'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                                            {stableSort(rows, getComparator(order, orderBy))
                                                //++ If pagination needed
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.orderId);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => handleClick(event, row.orderId)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.orderId}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="none"
                                                            >
                                                                {row.orderId.substring(18, 24)}
                                                            </TableCell>
                                                            <TableCell align="left">{row.date}</TableCell>
                                                            <TableCell align="left">{row.productType}</TableCell>
                                                            <TableCell align="left">{row.tag}</TableCell>
                                                            <TableCell align="left">{row.quantity}</TableCell>
                                                            <TableCell align="left">{row.liftStyle}</TableCell>
                                                            <TableCell align="left">{row.factor}</TableCell>
                                                            <TableCell align="left">{`$ ${row.payment}`}</TableCell>
                                                            <TableCell align="left">{row.paymentStatus}</TableCell>
                                                            <TableCell align="left">
                                                                <Link to={`/order/order-detail/${row.orderId}`}>
                                                                    <VisibilityIcon />
                                                                    View
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* If pagination need */}
                                {/* <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}
                            </Paper>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Larger padding"
                            />
                        </Box>)}

                    <div class="col text-center">
                        {(loading) ? (
                            <Loader />
                        ) : (
                            <>
                                <Link to={`/dealer/account-view/${id}?month=${parseInt(month) + 1}`}>
                                    <button type="button" className="btn btn-primary px-5 mx-5 radius-30 mb-3">
                                        ⬅ Previous Month
                                    </button>
                                </Link>
                                {(monthVal >= 0) ? (
                                    <>
                                        <Link to={`/dealer/account-view/${id}?month=${parseInt(month) - 1}`}>
                                            <button type="button" className="btn btn-primary px-5 radius-30 mb-3 ">
                                                Next Month ➡
                                            </button>
                                        </Link>
                                    </>) :
                                    (
                                        <button type="button" disabled className="btn btn-primary px-5 radius-30 mb-3"
                                        >Next Month ➡
                                        </button>
                                    )}
                            </>
                        )
                        }
                    </div>
                    <hr className="mt-3 mb-4" />
                    <div className="col-lg-10">
                        <h6 className="mb-2 text-uppercase">Action Section</h6>
                    </div>
                    {/* Payment Input UI validation  */}
                    {
                        isPaymentInput && (
                            <>
                                {selected.length === 1 ? (
                                    <EditPayment
                                        selected={selected}
                                    />) : (
                                    <>
                                        {setIsPaymentInput(false)}
                                    </>
                                )
                                }
                            </>
                        )}
                </main >

                <div className="overlay nav-toggle-icon" />
                <a href="" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt" />
                </a>
            </div >
        </>
    );
}