export const AnnualSaleAnalReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ANAL_ANNUAL_SALES_REQUEST':
            return { loading: true, }
        case 'ANAL_ANNUAL_SALES_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'ANAL_ANNUAL_SALES_FAIL':
            return { loading: false, error: action.payload }
        case 'ANAL_ANNUAL_SALES_RESET':
            return {}
        default:
            return state;
    }
}