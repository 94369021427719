import React from 'react'
import AddClientForm from '../../components/clients/AddClientForm'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'

const AddClient = () => {
    return (
        <>
            <>
                <div className="wrapper ">
                    <Header />
                    <Sidebar />
                    {/*start content*/}
                    <main className="page-content">
                        <AddClientForm />
                    </main>
                    <div className="overlay nav-toggle-icon" />
                    <a href="" className="back-to-top">
                        <i className="bx bxs-up-arrow-alt" />
                    </a>
                </div>
            </>
        </>

    )
}

export default AddClient