import React from 'react'
import PercentageBar from '../../components/analytics/graphs/PercentageBar'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'

const ListInventory = () => {
    return (
        <>
            <div className="wrapper ">
                <Header />
                <Sidebar />
                {/*start content*/}
                <main className="page-content">
                    <PercentageBar />
                </main >
            </div>
        </>
    )
}

export default ListInventory