import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({ nav }) => {
    const toggleHandle = () => {
        nav.isSidebar === 'wrapper' ? nav.setIsSidebar('wrapper toggled') : nav.setIsSidebar('wrapper')
    }
    return (
        <header className="top-header">
            <nav className="navbar navbar-expand">
                <button onClick={toggleHandle} type="button" class="btn btn-dark">
                    <div className="mobile-toggle-icon d-xl-none">
                        <i className="bi bi-list" />
                    </div>
                </button>
                <div className="top-navbar d-none d-xl-block">
                    <ul className="navbar-nav align-items-center">
                        <li className="nav-item">
                            <Link to='/' className="nav-link" >
                                Dashboard
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/action/add-action' className="nav-link" >
                                Add Action
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/add-appointment' className="nav-link" >
                                Add Appointment
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/order/add-order' className="nav-link" >
                                Add Orders
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="search-toggle-icon d-xl-none ms-auto">
                    <i className="bi bi-search" />
                </div>
                <form className="searchbar d-none d-xl-flex ms-auto">
                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
                        <i className="bi bi-search" />
                    </div>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Type here to search"
                    />
                    <div className="position-absolute top-50 translate-middle-y d-block d-xl-none search-close-icon">
                        <i className="bi bi-x-lg" />
                    </div>
                </form>
                <div className="top-navbar-right ms-3">
                    <ul className="navbar-nav align-items-center">
                        <li className="nav-item dropdown dropdown-large">
                            <a
                                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                                href="#"
                                data-bs-toggle="dropdown"
                            >
                                <div className="user-setting d-flex align-items-center gap-1">
                                    <img
                                        src="/assets/images/brand-logo-2.png"
                                        className="user-img"
                                        alt=""
                                    />
                                    <div className="user-name d-none d-sm-block">DBS Blinds</div>
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src="/assets/images/brand-logo-2.png"
                                                alt=""
                                                className="rounded-circle"
                                                width={120}
                                                height={60}
                                            />
                                            <div className="ms-3">
                                                <h6 className="mb-0 dropdown-user-name">DBS Blinds</h6>
                                                <small className="mb-0 dropdown-user-designation text-secondary">
                                                    Manufacturing Company
                                                </small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link to='/' className="dropdown-item" >
                                        <div className="d-flex align-items-center">
                                            <div className="setting-icon">
                                                <i className="bi bi-speedometer" />
                                            </div>
                                            <div className="setting-text ms-3">
                                                <span>Dashboard</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link to='/sign-in'
                                        className="dropdown-item"
                                        href="authentication-signup-with-header-footer.html"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="setting-icon">
                                                <i className="bi bi-lock-fill" />
                                            </div>
                                            <div className="setting-text ms-3">
                                                <span>Logout</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default Header