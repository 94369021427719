export const widthArray = [24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96]
export const heightArray = [18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120]
export const wMatrix = [
    [38, 42, 46, 50, 54, 58, 62, 66, 70, 74, 78, 83, 87, 91, 95, 99, 103, 107],
    [45, 51, 56, 61, 66, 71, 76, 82, 87, 92, 97, 102, 107, 112, 118, 123, 128, 133],
    [52, 58, 64, 70, 76, 82, 88, 95, 101, 107, 113, 119, 125, 131, 137, 143, 150, 156],
    [58, 65, 72, 79, 86, 93, 101, 108, 115, 122, 129, 136, 143, 150, 157, 164, 171, 178],
    [66, 74, 82, 90, 98, 107, 115, 128, 131, 139, 147, 155, 164, 172, 180, 188, 196, 204],
    [72, 81, 90, 99, 109, 118, 127, 136, 145, 154, 163, 172, 181, 191, 200, 209, 218, 227],
    [80, 90, 100, 110, 121, 131, 141, 151, 161, 172, 182, 192, 202, 212, 222, 233, 243, 253],
    [88, 99, 110, 121, 133, 144, 155, 166, 178, 189, 200, 211, 223, 234, 245, 258, 0, 0],
    [95, 108, 120, 132, 145, 157, 169, 182, 194, 206, 219, 231, 243, 256, 268, 0, 0, 0],
    [103, 117, 130, 143, 157, 170, 184, 197, 210, 224, 237, 251, 264, 277, 0, 0, 0, 0],
    [111, 125, 140, 154, 169, 183, 198, 212, 227, 241, 256, 270, 0, 0, 0, 0, 0, 0],
    [119, 134, 150, 165, 181, 196, 212, 228, 243, 259, 274, 0, 0, 0, 0, 0, 0, 0],
    [126, 143, 160, 176, 193, 210, 226, 243, 259, 276, 0, 0, 0, 0, 0, 0, 0, 0]
]