import React from 'react'

const ClientListTable = ({ p }) => {

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0">Customer Details</h5>
                        <form className="ms-auto position-relative">
                            <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-search" />
                            </div>
                            <input className="form-control ps-5" type="text" placeholder="search" />
                        </form>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table align-middle">
                            <thead className="table-secondary">
                                <tr>
                                    <th>Name</th>
                                    <th>Phone#</th>
                                    <th>Address</th>
                                    <th>email</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {p && p.map((c) =>
                                <>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                    <div className="">
                                                        <p className="mb-0">{c.name}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><a href="tel:PHONE_NUM">{c.phone}</a></td>
                                            <td>{c.address}</td>
                                            <td>{c.email}</td>
                                            <td>
                                                <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                    <a
                                                        href="javascript:;"
                                                        className="text-primary"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Views"
                                                    >
                                                        <i className="bi bi-eye-fill" />
                                                    </a>
                                                    <a
                                                        href="javascript:;"
                                                        className="text-warning"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Edit"
                                                    >
                                                        <i className="bi bi-pencil-fill" />
                                                    </a>
                                                    <a
                                                        href="javascript:;"
                                                        className="text-danger"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Delete"
                                                    >
                                                        <i className="bi bi-trash-fill" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientListTable