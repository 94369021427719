import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/layout/Header'
import Loader from '../../components/layout/Loader'
import Sidebar from '../../components/layout/Sidebar'
import AppointmentsTable from '../../components/tables/AppointmentsTable'
import { ListAppointAction, ListOrderAction } from '../../redux/actions/AppointActions'

const ListAppointment = ({ nav }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const listAppointment = useSelector(state => state.listAppointment)
    const { loading, appoints, success } = listAppointment
    const listOrder = useSelector(state => state.listOrder)
    const { loading: loadingOrders, orders, success: orderSuccess } = listOrder



    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
    }, [userInfo, navigate])

    useEffect(() => {
        dispatch(ListAppointAction())
        dispatch(ListOrderAction())

    }, []);

    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {/*start content*/}
                <main className="page-content">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0">Appointments</h5>
                                <form className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search" />
                                    </div>
                                    <input className="form-control ps-5" type="text" placeholder="search" />
                                </form>
                            </div>
                            {
                                loading ? (
                                    <Loader />
                                ) : (
                                    <AppointmentsTable p={appoints} />
                                )
                            }
                        </div>
                    </div >
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0">Orders in Production</h5>
                                <form className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search" />
                                    </div>
                                    <input className="form-control ps-5" type="text" placeholder="search" />
                                </form>
                            </div>
                            {loadingOrders ? (
                                <Loader />
                            ) : (
                                <AppointmentsTable p={orders} />
                            )}
                        </div>
                    </div >
                </main>
                <div className="overlay nav-toggle-icon" />

                <a href="" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt" />
                </a>

            </div>
        </>
    )
}

export default ListAppointment