import { Link } from 'react-router-dom'

const Detail = ({ p }) => {
    return (
        <>
            <main className="page-content">
                {/*breadcrumb*/}
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Appointment</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <Link to="/appointments">
                                        <i className="bx bx-calendar-event" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Detail
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/*end breadcrumb*/}
                <div className="card border shadow-none">
                    <div className="card-header py-3">
                        <div className="row align-items-center g-3">
                            <div className="col-12 col-lg-6">
                                <h5 className="mb-0">{p.name}</h5>
                            </div>
                            <div className="col-12 col-lg-6 text-md-end">
                                <Link to={`/edit-appointment/${p._id}`} className="btn btn-sm btn-secondary me-2">
                                    <i class="fadeIn animated bx bx-edit-alt" /> Edit
                                </Link>
                                <Link to="" className="btn btn-sm btn-danger me-2">
                                    <i className="bi bi-file-earmark-pdf-fill" /> Export as PDF
                                </Link>
                                <Link to=""
                                    onclick="window.print()"
                                    className="btn btn-sm btn-secondary"
                                >
                                    <i className="bi bi-printer-fill" /> Print
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-header py-2 bg-light">
                        <div className="row row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <div className="">
                                    <address className="m-t-5 m-b-5">
                                        <strong className="text-inverse">Address:</strong>
                                        <br />
                                        {p.address}
                                    </address>
                                </div>
                            </div>
                            <div className="col">
                                <div className="">
                                    <address className="m-t-5 m-b-5">
                                        <strong className="text-inverse">Contact Info</strong>
                                        <br />
                                        {p.phone}
                                        <br />
                                        {p.email}
                                    </address>
                                </div>
                            </div>
                            <div className="col">
                                <div className="">
                                    <div className="">
                                        <b>Payment</b>
                                    </div>
                                    <div className="invoice-detail">
                                        {'$' + p.payment}
                                        <br />
                                        {p.paymentStatus ? 'Paid' : 'Pending'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <span>Action <span class="badge rounded-pill bg-secondary">{p.action}</span></span>&emsp;
                        <span>Action Status {p.Status ? (<span class="badge rounded-pill bg-success">Completed</span>)
                            : (<span class="badge rounded-pill bg-danger">Pending</span>)}
                        </span>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <textarea class="form-control" id="textAreaExample2" rows="14" disabled>{p.des}</textarea>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-invoice">
                                <thead>
                                    <tr>
                                        <th>OFFICIAL INVOICE</th>
                                        <th className="text-center" width="10%">
                                            PRICE
                                        </th>
                                        <th className="text-center" width="10%">
                                            TAX
                                        </th>
                                        <th className="text-right" width="20%">
                                            LINE TOTAL
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="text-inverse">
                                                DBS Blinds
                                            </span>
                                            <br />
                                        </td>
                                        <td className="text-center">{p.payment}</td>
                                        <td className="text-center">Included</td>
                                        <td className="text-right">{p.payment}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row bg-light align-items-center m-0">
                            <div className="col col-auto p-4">
                                <p className="mb-0">SUBTOTAL</p>
                                <h4 className="mb-0">{p.payment}</h4>
                            </div>
                            <div className="col col-auto p-4">
                                <i className="bi bi-plus-lg text-muted" />
                            </div>
                            <div className="col col-auto me-auto p-4">
                                <p className="mb-0">GATEWAY FEE (0%)</p>
                                <h4 className="mb-0">$0.00</h4>
                            </div>
                            <div className="col bg-dark col-auto p-4">
                                <p className="mb-0 text-white">TOTAL</p>
                                <h4 className="mb-0 text-white">{p.payment}</h4>
                            </div>
                        </div>
                        {/*end row*/}
                        <hr />
                        {/* begin invoice-note */}
                        <div className="my-3">
                            * Make all cheques payable to DBS Blinds
                            <br />
                            * Payment is due within 3 days
                            <br />* If you have any questions concerning this invoice, contact
                            sales @dbsblinds.com
                        </div>
                        {/* end invoice-note */}
                    </div>
                    <div className="card-footer py-3">
                        <p className="text-center mb-2">THANK YOU FOR YOUR BUSINESS</p>
                        <p className="text-center d-flex align-items-center gap-3 justify-content-center mb-0">
                            <span className="">
                                <i className="bi bi-globe" /> www.dbsblinds.com
                            </span>
                            <span className="">
                                <i className="bi bi-telephone-fill" />+1-855-230-5385
                            </span>
                            <span className="">
                                <i className="bi bi-envelope-fill" /> sales@dbsblinds.com
                            </span>
                        </p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Detail