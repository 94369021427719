import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const Loader = () => {
    return (
        <>
            <div class="container">
                <div class="row">
                    <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#270092', '#1f2bdb', '#3921a0', '#4b55b3', '#8283ee']}
                    />
                </div>
            </div>
        </>
    )
}

export default Loader