import axios from "axios"
import { API_URI } from "../../constants/GlobalConstants"

export const createOrderAction = (val) => async (dispatch) => {
    try {
        dispatch({ type: 'PRODUCT_CREATE_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/product/create-product`, val)
        dispatch({ type: 'PRODUCT_CREATE_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'PRODUCT_CREATE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const listAttributeAction = (slug) => async (dispatch) => {
    console.log("🚀 ~ file: ProductActions.js:19 ~ listAttributeAction ~ slug:", slug)
    try {
        dispatch({ type: 'ATTRIBUTE_LIST_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/product/get-product-attributes${slug}`)
        dispatch({ type: 'ATTRIBUTE_LIST_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ATTRIBUTE_LIST_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}