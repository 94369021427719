import axios from "axios"
import { API_URI } from "../../constants/GlobalConstants"

export const createDealerOrderAction = (val) => async (dispatch) => {
    try {
        dispatch({ type: 'DEALER_ORDER_CREATE_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/order/create-dealer-order`, val, {
            responseType: 'blob',
        })
        dispatch({ type: 'DEALER_ORDER_CREATE_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'DEALER_ORDER_CREATE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const listOrderAction = () => async (dispatch) => {
    try {
        dispatch({ type: 'ORDER_LIST_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/order/list`)
        dispatch({ type: 'ORDER_LIST_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ORDER_LIST_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const OrderDetailAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'ORDER_DETAIL_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/order/order-detail/${id}`)
        dispatch({ type: 'ORDER_DETAIL_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ORDER_DETAIL_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}
export const OrderDeleteAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'ORDER_DELETE_REQUEST' })
        //passing the token

        await axios.delete(`${API_URI}/api/order/delete-order/${id}`)
        dispatch({ type: 'ORDER_DELETE_SUCCESS' })
    } catch (error) {
        dispatch({
            type: 'ORDER_DELETE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const OrderByDealerIDAction = (form) => async (dispatch) => {
    try {
        dispatch({ type: 'ORDER_BY_DEALER_ID_REQUEST' })
        //passing the token

        const { data } = await axios.post(`${API_URI}/api/order/order-by-dealer?month=${form.month}`, form)

        dispatch({ type: 'ORDER_BY_DEALER_ID_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ORDER_BY_DEALER_ID_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const UpdateOrderAction = (form) => async (dispatch) => {
    try {
        dispatch({ type: 'ORDER_UPDATE_REQUEST' })
        const { data } = await axios.put(`${API_URI}/api/order/update-order`, form, {
            responseType: 'blob',
        })
        dispatch({ type: 'ORDER_UPDATE_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ORDER_UPDATE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

//=========================Public======================================
export const CreateOrderAction = (form) => async (dispatch) => {
    try {
        dispatch({ type: 'ORDER_CREATE_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/order/create`, form)
        dispatch({ type: 'ORDER_CREATE_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ORDER_CREATE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}