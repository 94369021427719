import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    AppointCreateReducer, AppointDeleteReducer, AppointDetailReducer,
    AppointListReducer, AppointUpdateReducer, ListCompletedTaskReducer,
    ListInquiresReducer, ListPaymentPendingReducer, ListQuotationReducer,
    OrderListReducer
} from './redux/reducers/AppointReducers'
import { UserSignInReducer } from './redux/reducers/userReducers'
import { ClientCreateReducer, ClientListReducer } from './redux/reducers/ClientReducer'
import { DealerCreateReducer, DealerListReducer, DealerOrderStatsReducer, UpdateDealerNotesReducer, ViewDealerReducer } from './redux/reducers/DealerReducer'
import {
    createDealerOrderReducer,
    ListOrderReducer, OrderByDealerIDReducer, OrderCreateReducer, OrderDeleteReducer, OrderDetailReducer, UpdateOrderReducer
} from './redux/reducers/OrderReducer'
import { AnnualSaleAnalReducer } from './redux/reducers/AnalyticsReducers'
import { createProductReducer, listAttributeReducer } from './redux/reducers/ProductReducers'



const reducer = combineReducers({
    listAppointment: AppointListReducer,
    listOrder: OrderListReducer,
    listCompletedTask: ListCompletedTaskReducer,
    ListPaymentPending: ListPaymentPendingReducer,
    ListQuotation: ListQuotationReducer,
    ListInquiries: ListInquiresReducer,
    appointCreate: AppointCreateReducer,
    appointDetail: AppointDetailReducer,
    appointUpdate: AppointUpdateReducer,
    appointDelete: AppointDeleteReducer,

    orderList: ListOrderReducer,
    orderDetail: OrderDetailReducer,
    orderDelete: OrderDeleteReducer,
    orderDealerCreate: createDealerOrderReducer,
    OrderByDealerID: OrderByDealerIDReducer,
    UpdateOrder: UpdateOrderReducer,

    createClient: ClientCreateReducer,
    clientList: ClientListReducer,

    createDealer: DealerCreateReducer,
    listDealer: DealerListReducer,
    viewDealer: ViewDealerReducer,
    dealerOrderStats: DealerOrderStatsReducer,
    updateDealerNotes: UpdateDealerNotesReducer,

    annualSaleAnal: AnnualSaleAnalReducer,

    createProduct: createProductReducer,
    listAttribute: listAttributeReducer,

    userSignIn: UserSignInReducer,
    //------PUBLIC Routes-----------
    orderCreate: OrderCreateReducer,
})

//Getting the data from local storage
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userSignIn: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store