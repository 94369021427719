import axios from 'axios'
import {
    APPOINT_CREATE_FAIL, APPOINT_CREATE_REQUEST, APPOINT_CREATE_SUCCESS,
    APPOINT_DELETE_FAIL, APPOINT_DELETE_REQUEST, APPOINT_DELETE_SUCCESS,
    APPOINT_DETAIL_FAIL, APPOINT_DETAIL_REQUEST, APPOINT_DETAIL_SUCCESS,
    APPOINT_LIST_FAIL, APPOINT_LIST_REQUEST, APPOINT_LIST_SUCCESS,
    APPOINT_UPDATE_FAIL, APPOINT_UPDATE_REQUEST, APPOINT_UPDATE_SUCCESS,
    ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, P_PENDING_LIST_FAIL,
    P_PENDING_LIST_REQUEST, P_PENDING_LIST_SUCCESS, TASK_LIST_FAIL,
    TASK_LIST_REQUEST, TASK_LIST_SUCCESS
} from "../../constants/AppointConstants"
import { API_URI } from "../../constants/GlobalConstants"

export const ListAppointAction = () => async (dispatch) => {
    try {
        dispatch({ type: APPOINT_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-appoints`)
        dispatch({ type: APPOINT_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: APPOINT_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const ListOrderAction = () => async (dispatch) => {
    try {
        dispatch({ type: ORDER_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-orders`)
        dispatch({ type: ORDER_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const ListCompletedTaskAction = () => async (dispatch) => {
    try {
        dispatch({ type: TASK_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-completedTask`)
        dispatch({ type: TASK_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: TASK_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const ListPaymentPendingAction = () => async (dispatch) => {
    try {
        dispatch({ type: P_PENDING_LIST_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-pendingPayment`)
        dispatch({ type: P_PENDING_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: P_PENDING_LIST_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const listQuotationAction = () => async (dispatch) => {
    try {
        dispatch({ type: 'ACTION_LIST_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-quotations`)
        dispatch({ type: 'ACTION_LIST_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ACTION_LIST_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const listGeneralInquiriesAction = () => async (dispatch) => {
    try {
        dispatch({ type: 'IG_LIST_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-general-inquiries`)
        dispatch({ type: 'IG_LIST_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'IG_LIST_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const CreateAppointAction = (form) => async (dispatch) => {
    try {
        dispatch({ type: APPOINT_CREATE_REQUEST })
        const { data } = await axios.post(`${API_URI}/api/appointment/create`, form)
        dispatch({ type: APPOINT_CREATE_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: APPOINT_CREATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const AppointDetailAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: APPOINT_DETAIL_REQUEST })
        const { data } = await axios.get(`${API_URI}/api/appointment/get-appoint/${id}`)
        dispatch({ type: APPOINT_DETAIL_SUCCESS, payload: data })
        // console.log(data)
    } catch (error) {
        dispatch({
            type: APPOINT_DETAIL_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const UpdateAppointAction = (appoint) => async (dispatch) => {
    try {
        dispatch({ type: APPOINT_UPDATE_REQUEST })
        //passing the token

        const { data } = await axios.put(`${API_URI}/api/appointment/edit-appoint/${appoint.id}`, appoint)
        dispatch({ type: APPOINT_UPDATE_SUCCESS })
    } catch (error) {
        dispatch({
            type: APPOINT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const DeleteAppointAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: APPOINT_DELETE_REQUEST })
        //passing the token

        await axios.delete(`${API_URI}/api/appointment/delete-task/${id}`)
        dispatch({ type: APPOINT_DELETE_SUCCESS })
    } catch (error) {
        dispatch({
            type: APPOINT_DELETE_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}