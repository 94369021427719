import { Link } from 'react-router-dom'

const AppointmentsTable = ({ p, type }) => {
    console.log("🚀 ~ file: AppointmentsTable.jsx:4 ~ AppointmentsTable ~ type:", p)

    const isoToDate = (iso) => {
        // const isoStr1 = '2022-09-20T09:00:00.000Z'
        const date1 = new Date(iso);
        return (date1.toString().slice(0, 10))
    }

    const isoToTime = (iso) => {
        // const isoStr1 = '2022-09-20T09:00:00.000Z'
        const date1 = new Date(iso);
        return (date1.toString().slice(16, 21))
    }

    return (
        <>
            <div className="table-responsive mt-3">
                <table className="table align-middle mb-0">
                    <thead className="table-light">
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Name</th>
                            <th>Action</th>
                            <th>A.Status</th>
                            <th>Phone#</th>
                            <th>Address</th>
                            {(() => {
                                switch (type) {
                                    case 'Quotation':
                                        return (
                                            <>
                                                <th>Quotation</th>
                                            </>
                                        )
                                    case 'Inquiry':
                                        return (
                                            <>
                                                <th>Approx Est</th>
                                            </>
                                        )
                                    case 'Payment-pending':
                                        return (
                                            <>
                                                <th>Payment</th>
                                                <th>P.Status</th>
                                            </>
                                        )
                                    default:
                                        return null
                                }
                            })()}
                        </tr>

                    </thead>
                    {p && p.map((a) =>
                        <>
                            {/* {a.timing.toLocaleDateString('en-US')} */}
                            <tbody>
                                <tr>
                                    <td>{isoToDate(a.timing)}</td>
                                    <td>{isoToTime(a.timing)}</td>
                                    <td><Link to={`/detail-appointment/${a._id}`}>{a.name}</Link></td>

                                    {(() => {
                                        switch (a.action) {
                                            case 'Measurement':
                                                return (
                                                    <td>
                                                        <span className='badge rounded-pill bg-primary '>{a.action}</span>
                                                    </td>
                                                )
                                            case 'Installation':
                                                return (
                                                    <td>
                                                        <span className='badge rounded-pill bg-info text-dark'>{a.action}</span>
                                                    </td>
                                                )
                                            case 'Quotation':
                                                return (
                                                    <td>
                                                        <span className='badge rounded-pill bg-warning text-dark'>{a.action}</span>
                                                    </td>
                                                )
                                            case 'General-Inquiry':
                                                return (
                                                    <td>
                                                        <span className='badge rounded-pill bg-secondary'>{a.action}</span>
                                                    </td>
                                                )
                                            case 'Production':
                                                return (
                                                    <td>
                                                        <span className='badge rounded-pill bg-success text-dark'>{a.action}</span>
                                                    </td>
                                                )
                                            default:
                                                return (
                                                    <td>
                                                        <span className=''>{a.action}</span>
                                                    </td>
                                                )
                                        }
                                    })()}

                                    <td>
                                        {a.actionStatus ? (
                                            <span className="badge bg-light-success text-light w-55">
                                                {'Completed'}
                                            </span>
                                        ) : (
                                            <span className="badge bg-light-danger text-light w-55">
                                                {'Pending'}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <a href={`tel:${a.phone}`}>{a.phone}</a>
                                    </td>
                                    <td>
                                        {a.address}
                                    </td>
                                    {(() => {
                                        switch (type) {
                                            case 'Quotation':
                                                return (
                                                    <>
                                                        <td>
                                                            {'$' + a.payment}
                                                        </td>
                                                    </>
                                                )
                                            case 'Inquiry':
                                                return (
                                                    <>
                                                        <td>
                                                            {'$' + a.payment}
                                                        </td>
                                                    </>
                                                )
                                            case 'Payment-pending':
                                                return (
                                                    <>
                                                        <td>
                                                            {'$' + a.payment}
                                                        </td>
                                                        <td>
                                                            {a.paymentStatus ? (
                                                                <span className="badge bg-light-success text-light w-55">
                                                                    {'Completed'}
                                                                </span>
                                                            ) : (
                                                                <span className="badge bg-light-danger text-light w-55">
                                                                    {'Pending'}
                                                                </span>
                                                            )}
                                                        </td>
                                                    </>
                                                )
                                            default:
                                                return null
                                        }
                                    })()}
                                </tr>
                            </tbody>
                        </>
                    )

                    }

                </table>
            </div>
        </>
    )
}

export default AppointmentsTable