import { CLIENT_CREATE_FAIL, CLIENT_CREATE_REQUEST, CLIENT_CREATE_SUCCESS, CLIENT_LIST_FAIL, CLIENT_LIST_REQUEST, CLIENT_LIST_SUCCESS } from "../../constants/ClientConstants";

export const ClientListReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_LIST_REQUEST:
            return { loading: true, appoint: [] }
        case CLIENT_LIST_SUCCESS:
            return { loading: false, success: true, clients: action.payload }
        case CLIENT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const ClientCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_CREATE_REQUEST:
            return { loading: true, appoint: [] }
        case CLIENT_CREATE_SUCCESS:
            return { loading: false, success: true }
        case CLIENT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}
