import React from 'react'
import { LineWave } from 'react-loader-spinner'

const LoaderLin = () => {
    return (
        <>
            <div class="container">
                <div class="row">
                    <LineWave
                        height="100"
                        width="100"
                        color="#ffffff"
                        ariaLabel="line-wave"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        firstLineColor=""
                        middleLineColor=""
                        lastLineColor=""
                    />
                </div></div>
        </>
    )
}

export default LoaderLin