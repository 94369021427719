export const AdditionInput = ({ setAddition, addition }) => {
    const handleAdditionInput = (e) => {
        //getting values from user input
        const { name, value } = e.target
        setAddition({ ...addition, [name]: value })
    }
    return (
        <>

            <div className="col-2">
                <label className="form-label">Addition</label>
                <input type="text" className="form-control" placeholder="Addition" name='val'
                    value={addition.val} onChange={handleAdditionInput}
                />
            </div>
            {/* <div className="col-1 mt-4 mx-3 " >
                <form
                // onSubmit={ }
                >
                    <button type='submit'
                        // onSubmit={handleSplitSubmit}
                        className="mt-1 btn btn-success">
                        Apply
                    </button>
                </form>
            </div> */}
            <div className="col-1 mt-4 float-right" >
                <form>
                    <button type='submit'
                        onClick={(e) => setAddition({ trigger: false })}
                        className="mt-1 btn btn-danger">
                        Cancel
                    </button>
                </form>
            </div>
        </>
    )
}
