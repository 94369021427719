import React from 'react'

const AddAttribute = ({ para }) => {
    const { attriName } = para
    const section = para.isCreate.section

    const onClickCreate = (e, attriName) => {
        e.preventDefault()
        para.setIsCreate({
            section: attriName,
            show: true
        })
    }

    const cancelCreate = (e) => {
        e.preventDefault()
        para.setIsCreate({
            section: '',
            show: false
        })
    }

    return (
        <div className="card shadow-none border">
            <div className="card-header">
                <h6 className="mb-0">{para.title}</h6>
            </div>
            {para.isCreate && para.isCreate.show && para.isCreate.section === attriName ?
                (
                    <>
                        <form className="row g-3 px-3 py-3">
                            <div className="col-8">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Client email"
                                    value={para.newAttri} onChange={(e) => para.setNewAttri(e.target.value)}
                                />
                            </div>
                            <div className="col-2">
                                <button type="submit"
                                    className="btn btn-primary w-100"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        para.submitAttribute({
                                            ...para.selectedAttri,
                                            attriName: para.newAttri,
                                            attributeType: attriName
                                        })
                                    }}
                                >
                                    Create
                                </button>
                            </div>
                            <div className="col-2">
                                <button type="submit"
                                    className="btn btn-secondary w-100"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        para.setIsCreate({
                                            section: '', show: false,
                                        })
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </>
                ) : (

                    < div className="card-body">
                        <form className="row g-3">
                            <div className="col-12">
                                <label className="form-label">Select the <b>{para.title}</b> or&nbsp;
                                    <button className="btn btn-link px-0" onClick={(e) => onClickCreate(e, attriName)}>
                                        Create new {para.title}
                                    </button>
                                </label>
                                <select className="form-select"
                                    value={para.selectedAttri.attriName}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        para.setSelectedAttri(
                                            {
                                                ...para.selectedAttri,
                                                [attriName.toString()]: e.target.value,
                                            }
                                        )
                                        para.dispatch(para.listAttributeAction(
                                            `?productType=${para.selectedAttri.productType}&lift=${para.selectedAttri.lift}&valance=${para.selectedAttri.valance}&material=${para.selectedAttri.material}&bottom=${para.selectedAttri.bottom}&addon=${para.selectedAttri.addon}`
                                        ))
                                    }}
                                >
                                    <option></option>
                                    {para.attributes && para.attributes.map((a) => (
                                        <option value={a._id}>{a.name}</option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div >
                )
            }
            {/* -----------Creating new attribute------------- */}
            {/* {
                para.isCreate && para.isCreate.show && para.isCreate.section === attriName &&

            } */}
        </div >
    )
}

export default AddAttribute