export const DeductionInput = ({ setDeduction, deduction }) => {
    const handleDeductionInput = (e) => {
        //getting values from user input
        const { name, value } = e.target
        setDeduction({ ...deduction, [name]: value })
    }
    return (
        <>

            <div className="col-2">
                <label className="form-label">Deduction</label>
                <input type="text" className="form-control" placeholder="Deduction" name='val'
                    value={deduction.val} onChange={handleDeductionInput}
                />
            </div>
            {/* <div className="col-1 mt-4 mx-3 " >
                <form
                // onSubmit={ }
                >
                    <button type='submit'
                        // onSubmit={handleSplitSubmit}
                        className="mt-1 btn btn-success">
                        Apply
                    </button>
                </form>
            </div> */}
            <div className="col-1 mt-4 float-right" >
                <form>
                    <button type='submit'
                        onClick={(e) => setDeduction({ trigger: false })}
                        className="mt-1 btn btn-danger">
                        Cancel
                    </button>
                </form>
            </div>
        </>
    )
}