import { useEffect, useState } from "react";
import { deleteOrderbyId, updateOrderArr, updateOrderFactor } from "../functions/OrderFunctions";
import OrderOverviewUI from "./tables/OrderOverviewUI";
import { OrderTableHeader, OrderTableValues } from "./tables/OrderTableUI";


const OrderEditTable = ({ orderArr, setOrderArr, productType, setOrderFactor, headrail }) => {

    const [editProductId, setEditProductId] = useState(null);

    const [widthEdit, setWidthEdit] = useState(0);
    const [heightEdit, setHeightEdit] = useState(0);
    const [controlEdit, setControlEdit] = useState('right');
    const [liftEdit, setLiftEdit] = useState('corded');
    const [mountEdit, setMountEdit] = useState('inside');
    const [remarkEdit, setRemarkEdit] = useState('');
    const [editFormData, setEditFormData] = useState({
        width: 0,
        height: 0,
        control: '',
        lift: '',
        mount: '',
        remark: ''
    });

    //*Delete the single order from Arr
    const handleDeleteData = (orderId) => {
        setOrderArr(deleteOrderbyId(orderId, orderArr))
    }

    //+Cancels the single order Edit 
    const cancelOrderEdit = () => {
        setEditProductId(null)
    }

    //+ When the Edit button is clicked then the button
    //+ will pass the data of the Order that need to modify
    const handleEditClick = (e, editOrder) => {
        setEditProductId(editOrder.id)
        setWidthEdit(editOrder.width)
        setHeightEdit(editOrder.height)
        setControlEdit(editOrder.control)
        setLiftEdit(editOrder.lift)
        setMountEdit(editOrder.mount)
        setRemarkEdit(editOrder.remark)
        setEditFormData({ ...editOrder })
    }

    //=Update the order in the Array
    const handleEditDataSubmit = () => {
        //Updating the order 
        setOrderArr(updateOrderArr({
            OrderArr: orderArr, editProductId,
            productType: productType,
            widthEdit, heightEdit, remarkEdit, headrail
        }))
        setEditProductId(null)
    }

    return (
        <>
            <table
                id="example"
                className="table table-striped table-bordered"
                style={{ width: "100%" }}
            >
                <OrderTableHeader
                    productType={productType}
                    isEdit={true}
                />
                <tbody>
                    {
                        orderArr.map((o, index) => (
                            <>
                                {
                                    editProductId === o.id ? (
                                        <tr>
                                            <td>{'index'}</td>
                                            <td className="col-2">
                                                <input type="text" className="form-control" placeholder="Width"
                                                    value={widthEdit} onChange={(e) => setWidthEdit(e.target.value)}
                                                />
                                            </td>
                                            <td className="col-2">
                                                <input type="text" className="form-control" placeholder="Width"
                                                    value={heightEdit} onChange={(e) => setHeightEdit(e.target.value)}
                                                />
                                            </td>
                                            <td className="h5"></td>
                                            <td className="h5"></td>
                                            <td className="h5"></td>
                                            <td className="col">
                                                <input type="text" className="form-control" placeholder="control"
                                                    value={remarkEdit} onChange={(e) => setRemarkEdit(e.target.value)}
                                                />
                                            </td>
                                            <td className="col-1 mt-4 " >
                                                <div >
                                                    <button type='submit'
                                                        onClick={handleEditDataSubmit}
                                                        className="mt-1 btn btn-primary  btn-sm px-3 w-100">
                                                        Submit Edit
                                                    </button>
                                                </div>
                                                <div >
                                                    <button type='submit'
                                                        onClick={cancelOrderEdit}
                                                        className="mt-1 btn btn-danger  btn-sm w-100">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            < tr >
                                                <td>{index + 1}</td>
                                                <OrderTableValues o={o} productType={productType} />
                                                <td >
                                                    <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                        {/* Validating the order id */}
                                                        <button
                                                            onClick={(e) => handleEditClick(e, o)}
                                                            className="text-warning bg-dark" data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom" title="Edit">
                                                            <i className="bi bi-pencil-fill"></i>
                                                        </button>
                                                        <a href="javascript:;" className="text-primary" data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom" title="Views"><i className="bi bi-eye-fill"></i>
                                                        </a>
                                                        <button
                                                            onClick={() => handleDeleteData(o.id)}
                                                            className="text-danger bg-dark" data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom" title="Delete"><i className="bi bi-trash-fill"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }

                                <>

                                </>
                            </>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default OrderEditTable