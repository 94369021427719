import React from 'react'
import { Link } from 'react-router-dom';


const ActionListTable = ({ a, b }) => {
    const isoToDate = (iso) => {
        // const isoStr1 = '2022-09-20T09:00:00.000Z'
        const date1 = new Date(iso);
        return (date1.toString().slice(0, 10))
    }

    const isoToTime = (iso) => {
        // const isoStr1 = '2022-09-20T09:00:00.000Z'
        const date1 = new Date(iso);
        return (date1.toString().slice(16, 21))
    }
    return (
        <>
            <div className="table-responsive mt-3">
                <table className="table align-middle mb-0">
                    <thead className="table-light">
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Name</th>
                            <th>Phone#</th>
                            <th>Address</th>
                            {b === 'Quotation' && <th>Quotation</th>}
                        </tr>
                    </thead>
                    {a && a.map((a) =>
                        <>
                            <tbody>
                                <tr>
                                    <td>{isoToDate(a.timing)}</td>
                                    <td>{isoToTime(a.timing)}</td>
                                    <td><Link to={`/detail-appointment/${a._id}`}>{a.name}</Link></td>
                                    <td>
                                        <Link to={`tel:${a.phone}`}>{a.phone}</Link>
                                    </td>
                                    <td>
                                        {a.address}
                                    </td>
                                    {b === 'Quotation' &&
                                        <td>
                                            {'$' + a.payment}
                                        </td>
                                    }

                                </tr>
                            </tbody>
                        </>
                    )}

                </table>
            </div>

        </>
    )
}

export default ActionListTable