import OrderTableView from "./OrderComp/OrderTableView";
import OrderEditTable from "./OrderComp/OrderEditTable";
import OrderOverviewUI from "./OrderComp/tables/OrderOverviewUI";
import { useEffect, useState } from "react";
import { getProductionData, updateOrderFactor } from "./functions/OrderFunctions";
import { useDispatch, useSelector } from "react-redux";
import { UpdateOrderAction } from "../../redux/actions/OrderAction";
import Loader from "../layout/Loader";
import { useNavigate } from "react-router";
import Badge from "../layout/Badge";
import fileDownload from 'js-file-download';


const OrderDetail = ({ o, isEdit, isSetEdit }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const orderUpdate = useSelector(state => state.UpdateOrder)
    const { loading: updateLoading, data, success: successOrder } = orderUpdate

    const [orderFactor, setOrderFactor] = useState(o.orderFactor);
    const [orderArr, setOrderArr] = useState(o.orderArray);
    // console.log("🚀 ~ file: OrderDetail.jsx:11 ~ OrderDetail ~ orderArr", orderArr)
    //+Add Product - method
    const [addWidth, setAddWidth] = useState(0);
    const [addHeight, setAddHeight] = useState(0);
    const [addRemark, setAddRemark] = useState('');

    const [updateLiftStyle, setUpdateLiftStyle] = useState(o.liftStyle);
    const [updateProMaterial, setUpdateProMaterial] = useState(o.productMaterial);
    const [headrail, setHeadrail] = useState(o.productHeadrail || '');
    const [updateRemark, setUpdateRemark] = useState(o.remark);
    const [updateTag, setUpdateTag] = useState(o.tag);

    //-Cancel the Order Edits and returns to View
    const cancelUpdate = () => {
        isSetEdit(false)
    }

    //+Adding additional product to the Array
    const handleAdditionalPro = () => {
        const proData = getProductionData(
            o.productType, addWidth,
            addHeight, addRemark, headrail
        )
        setOrderArr([...orderArr, proData])
    }

    const handleUpdateOrder = async () => {
        const val = {
            orderId: o._id, dealerId: o.dealerId, dealerName: o.dealerName, productTag: updateTag,
            productMaterial: updateProMaterial, liftStyle: updateLiftStyle,
            orderRemark: updateRemark, order: orderArr,
            orderFactor: orderFactor, productType: o.productType, headrail
        }
        await dispatch(UpdateOrderAction(val))
    }

    // Any change in orderArr will update the factor 
    useEffect(() => {
        dispatch({ type: 'ORDER_UPDATE_RESET' })
        setOrderFactor(updateOrderFactor(orderArr))
    }, [orderArr]);

    //download the data
    useEffect(() => {
        if (data) {
            fileDownload(data, `${o.dealerName} ${o.productType} ${updateTag}.pdf`)
        }
    }, [data])


    return (
        <>
            {successOrder ? (
                <Badge p={{
                    type: 'success',
                    message: 'Action completed successfully👍',
                    redirect: `/order/order-detail/` + o._id,
                    redirectName: 'View updated order'
                }} />
            ) : (updateLoading ? <Loader /> : (
                <>
                    {!isEdit ? (
                        <>
                            <div className="row mt-5 mb-4">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <h6 className="mb-2 text-uppercase">Order Info</h6>
                                    </div>
                                </div>
                                <div className="col-lg-6 row g-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"> Company Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Company" disabled
                                            value={o.dealerName}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Order Tag </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Not Specified"
                                            value={o.tag} disabled
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Lift Style </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Lift Style"
                                            value={o.liftStyle} disabled
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Product Type</label>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Not Specified"
                                            value={o.productType} disabled
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Headrail/Cassette</label>
                                        <input type="text"
                                            className="form-control"
                                            placeholder="Not Specified"
                                            value={headrail} disabled
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Product Material</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Fabric/Material"
                                            value={o.productMaterial} disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 row g-3 mb-4">
                                    <div className="col-12">
                                        <label className="form-label">Order Remark</label>
                                        <textarea
                                            className="form-control"
                                            placeholder=" Remarks, order tag..."
                                            rows={5}
                                            cols={4}
                                            defaultValue={""}
                                            value={o.remark} disabled
                                        />
                                    </div>
                                </div>
                                <hr />
                                <h6 className="mt-2 mb-2 text-uppercase">Order Overview</h6>
                                <OrderOverviewUI
                                    productType={o.productType}
                                    orderFactor={orderFactor}
                                />
                                <hr />
                                <h6 className="mt-4 mb-2 text-uppercase">Order Data Table </h6>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <OrderTableView o={o} />
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row mt-5 mb-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h6 className="mb-2 text-uppercase">Order Edit</h6>
                                    </div>
                                    <div className="col-lg-3">
                                        <a href={``}>
                                            <button type="button"
                                                className="btn btn-secondary px-5 radius-30"
                                                onClick={cancelUpdate}
                                            >
                                                Cancel update
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-lg-3">
                                        <button type="button"
                                            className="btn btn-success px-5 radius-30"
                                            onClick={handleUpdateOrder}
                                        >
                                            Update the Order
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-6 row g-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"> Company Name</label>
                                        <input
                                            type="text" disabled
                                            className="form-control"
                                            placeholder="Company"
                                            value={o.dealerName}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Order Tag </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Not Specified"
                                            value={updateTag}
                                            onChange={(e) => setUpdateTag(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Lift Style </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Lift Style"
                                            value={updateLiftStyle} onChange={(e) => setUpdateLiftStyle(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Product Type</label>
                                        <input type="text" disabled
                                            className="form-control"
                                            placeholder="Not Specified"
                                            value={o.productType}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Headrail/Cassette</label>
                                        <input type="text" disabled
                                            className="form-control"
                                            placeholder="Not Specified"
                                            value={headrail}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"> Product Material</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Fabric/Material"
                                            value={updateProMaterial}
                                            onChange={(e) => setUpdateProMaterial(e.target.value)}
                                        />
                                    </div>

                                </div>
                                <div className="col-lg-6 row g-3">
                                    <div className="col-12">
                                        <label className="form-label">Order Remark</label>
                                        <textarea
                                            className="form-control"
                                            placeholder=" Remarks, order tag..."
                                            rows={5} cols={4}
                                            defaultValue={""}
                                            value={updateRemark}
                                            onChange={(e) => setUpdateRemark(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <h6 className="mt-4 mb-2 text-uppercase">Order Overview</h6>
                                <OrderOverviewUI
                                    productType={o.productType}
                                    orderFactor={orderFactor}
                                />
                                <h6 className="mt-4 mb-2 text-uppercase">Order Data Table </h6>
                                <hr />
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <OrderEditTable
                                                orderArr={orderArr}
                                                setOrderArr={setOrderArr}
                                                productType={o.productType}
                                                setOrderFactor={setOrderFactor}
                                                headrail={headrail}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/*-----Add order to OrderArray----*/}
                                <h6 className="mb-0 text-uppercase mb-2">Add Additional Product </h6>
                                <div className="row mb-3">
                                    <div className="col-2">
                                        <label className="form-label">Width</label>
                                        <input type="text" className="form-control" placeholder="Width"
                                            value={addWidth} onChange={(e) => setAddWidth(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <label className="form-label">Height</label>
                                        <input type="text" className="form-control" placeholder="Width"
                                            value={addHeight} onChange={(e) => setAddHeight(e.target.value)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Remark</label>
                                        <input type="text" className="form-control" placeholder="control"
                                            value={addRemark} onChange={(e) => setAddRemark(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-1 mt-4" >
                                        <button type='submit'
                                            onClick={handleAdditionalPro}
                                            className="mt-1 btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </>
                    )
                    }
                </>
            ))}
        </>
    )
}


export default OrderDetail