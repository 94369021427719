export function accumulateCounter(key) {

}

export const isoToDate = (iso) => {
    // const isoStr1 = '2022-09-20T09:00:00.000Z'
    const date1 = new Date(iso);
    return (date1.toString().slice(0, 10))
}

export const isoToTime = (iso) => {
    // const isoStr1 = '2022-09-20T09:00:00.000Z'
    const date1 = new Date(iso);
    return (date1.toString().slice(16, 21))
}

//- Converts the nested objects to array
export const objToArr = (obj) => {
    return Object.entries(obj)
}