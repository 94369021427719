import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import ActionListTable from '../../components/actions/ActionListTable'
import Header from '../../components/layout/Header'
import Loader from '../../components/layout/Loader'
import Sidebar from '../../components/layout/Sidebar'
import { listGeneralInquiriesAction, listQuotationAction } from '../../redux/actions/AppointActions'

const ViewAction = ({ nav }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const actionList = useSelector(state => state.ListQuotation)
    const { loading, actions } = actionList
    const inquiresList = useSelector(state => state.ListInquiries)
    const { loading: LoadingInquiries, actions: inquiries } = inquiresList


    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
    }, [userInfo, navigate])

    useEffect(() => {
        dispatch(listQuotationAction())
        dispatch(listGeneralInquiriesAction())
    }, []);

    console.log(inquiries)
    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {/*start content*/}
                <main className="page-content">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0">Actions & Tasks</h5>
                                <form className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search" />
                                    </div>
                                    <input className="form-control ps-5" type="text" placeholder="search" />
                                </form>
                            </div>
                            {loading ? <Loader /> : (
                                <ActionListTable a={actions} b={'Quotation'} />
                            )}
                        </div>
                    </div >

                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0">General Inquiries</h5>
                                <form className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search" />
                                    </div>
                                    <input className="form-control ps-5" type="text" placeholder="search" />
                                </form>
                            </div>
                            {LoadingInquiries ? <Loader /> : (
                                <ActionListTable a={inquiries} b={'Inquiries'} />
                            )}
                        </div>
                    </div >

                </main>
                <div className="overlay nav-toggle-icon" />

                <a href="" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt" />
                </a>

            </div>
        </>
    )
}

export default ViewAction