import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({ nav }) => {

    const toggleHandle = () => {
        nav.isSidebar === 'wrapper' ? nav.setIsSidebar('wrapper toggled') : nav.setIsSidebar('wrapper')
    }
    const toggleMainMenu = () => {
        if (window.innerWidth > 1200) {
            nav.setIsSidebar('wrapper')
        }
    }

    return (
        <>
            <aside className="sidebar-wrapper">
                <div className="iconmenu">
                    <button onClick={toggleHandle} style={{ marginRight: '-14px' }}>
                        <div className="nav-toggle-box">
                            <div className="nav-toggle-icon">
                                <i className="bi bi-list" />
                            </div>
                        </div>
                    </button>
                    <ul className="nav nav-pills flex-column">

                        <li className="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Dashboards">
                            <button onClick={toggleMainMenu} className="nav-link" data-bs-toggle="pill"
                                data-bs-target="#pills-dashboards" type="button">
                                <i className="bi bi-house-door-fill" />
                            </button>
                        </li>


                        <li class="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Actions">
                            <button onClick={toggleMainMenu} class="nav-link" data-bs-toggle="pill"
                                data-bs-target="#pills-action" type="button">
                                <i className="fadeIn animated bx bx-command" />
                            </button>
                        </li>

                        <li className="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Appointments">
                            <button onClick={toggleMainMenu} className="nav-link" data-bs-toggle="pill"
                                data-bs-target="#pills-application" type="button">
                                <i className="bx bx-calendar" />
                            </button>
                        </li>

                        <li class="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Order">
                            <button onClick={toggleMainMenu} class="nav-link" data-bs-toggle="pill"
                                data-bs-target="#pills-widgets"
                                type="button">
                                <i className="lni lni-cart" />
                            </button>
                        </li>

                        <li className="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Dealer">
                            <button
                                onClick={toggleMainMenu}
                                className="nav-link" data-bs-toggle="pill"
                                data-bs-target="#dealer"
                                type="button">
                                <i class="fadeIn animated bx bx-store" />
                            </button>
                        </li>

                        <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Charts">
                            <button
                                onClick={toggleMainMenu}
                                className="nav-link" data-bs-toggle="pill" data-bs-target="#pills-charts" type="button">
                                <i class="bi bi-graph-up" />
                            </button>
                        </li>

                        <li class="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Inventory">
                            <button onClick={toggleMainMenu} class="nav-link" data-bs-toggle="pill"
                                data-bs-target="#pills-inventory"
                                type="button"><i class="fadeIn animated bx bx-box" />
                            </button>
                        </li>

                        <li class="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Product Management">
                            <button onClick={toggleMainMenu} class="nav-link" data-bs-toggle="pill"
                                data-bs-target="#products-management"
                                type="button"><i class="fadeIn animated bx bx-shopping-bag"></i>
                            </button>
                        </li>

                        <li class="nav-item" data-bs-toggle="tooltip"
                            data-bs-placement="right" title="Apps">
                            <button onClick={toggleMainMenu} class="nav-link" data-bs-toggle="pill"
                                data-bs-target="#pills-apps"
                                type="button"><i class="lni lni-app-store"></i>
                            </button>
                        </li>

                    </ul>
                </div>
                <div className="textmenu">
                    <div className="brand-logo">
                        <img src="/assets/images/brand-logo-2.png" width={140} alt="" />
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade" id="pills-dashboards">
                            <div className="list-group list-group-flush">
                                <div className="list-group-item">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-0">Dashboards</h5>
                                    </div>
                                    <small className="mb-0">An Overview Management</small>
                                </div>
                                <Link to="/" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="lni lni-home" />
                                    Home
                                </Link>
                                {/* <a href="/add-action" className="list-group-item">
                                <i class="fadeIn animated bx bx-add-to-queue" />
                                Add Action
                            </a>
                            <a href="/add-appointment" className="list-group-item">
                                <i className="bx bx-calendar-plus" />
                                Add Appointment
                            </a> */}
                            </div>
                        </div>

                        <div class="tab-pane fade" id="pills-action">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-0">Action/Task</h5>
                                    </div>
                                    <small class="mb-0">Manage all actions</small>
                                </div>
                                <Link to="/action/add-action" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i class="fadeIn animated bx bx-file" />
                                    Create Action
                                </Link>
                                <Link to="/action/list-action" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i class="fadeIn animated bx bx-list-ul" />
                                    List Action
                                </Link>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-application">
                            <div className="list-group list-group-flush">
                                <div className="list-group-item">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-0">Appointments</h5>
                                    </div>
                                    <small className="mb-0">View all listed Schedules</small>
                                </div>
                                <Link to="/appointments" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="bx bx-calendar-event" />
                                    All Appointments
                                </Link>
                                <Link to="/add-appointment" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="bx bx-calendar-plus" />
                                    Add Appointment
                                </Link>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-widgets">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-0">Order System</h5>
                                    </div>
                                    <small class="mb-0">Order Management</small>
                                </div>
                                <Link to="/order/add-order" class="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i class="bi bi-box"></i>Add Order</Link>
                                <Link to="/order/list" class="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i class="bi bi-bar-chart"></i>Order List</Link>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="dealer">
                            <div className="list-group list-group-flush">
                                <div className="list-group-item">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-0">Dealer Manager</h5>
                                    </div>
                                    <small className="mb-0">Dealer Panel</small>
                                </div>
                                <Link to="/dealer/list-dealer" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="bx bx-user-circle" />
                                    All Dealer
                                </Link>
                                <Link to="/dealer/add-dealer" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="bx bx-user-plus" />
                                    Add Dealer
                                </Link>
                                {/* <Link to="/dealer/test" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="bx bx-user-plus" />
                                    Test
                                </Link> */}
                            </div>
                        </div>

                        <div class="tab-pane fade" id="pills-charts">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-0">Analytics</h5>
                                    </div>
                                    <small class="mb-0">Analytics, statistics & Reports Management</small>
                                </div>
                                <Link to="/analytics/annual-sales" class="list-group-item"><i class="bi bi-bar-chart" />Annual Sales </Link>
                                <Link to="/analytics/productions" class="list-group-item"><i class="bi bi-pie-chart"></i>Production Stat</Link>
                                {/* <a href="charts-highcharts.html" class="list-group-item"><i class="bi bi-graph-up"></i>Highcharts</a> */}
                            </div>
                        </div>

                        <div class="tab-pane fade" id="pills-inventory">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-0">Inventory</h5>
                                    </div>
                                    <small class="mb-0">Inventory Management</small>
                                </div>
                                <a href="/inventory/inventory-list" className="list-group-item">
                                    <i class="fadeIn animated bx bx-file" />
                                    View Inventory
                                </a>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="products-management">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-0">Products Management</h5>
                                    </div>
                                    <small class="mb-0">Products & Attributes</small>
                                </div>
                                <Link to="/product-management/add-product-attributes" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i class="fadeIn animated bx bx-message-square-add" />
                                    Add Product/Attribute
                                </Link>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-apps">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-0">Applications</h5>
                                    </div>
                                    <small class="mb-0">ERP Applications</small>
                                </div>
                                <Link to="/app/invoice-generator" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i class="fadeIn animated bx bx-file" />
                                    Invoice/Estimate Generator
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

export default Sidebar