import React, { useState } from 'react'
import { nanoid } from 'nanoid'
import { useDispatch, useSelector } from 'react-redux'
import { CreateOrderAction } from '../../redux/actions/OrderAction'
import Loader from '../../components/layout/Loader'
import Badge from '../../components/layout/Badge'
import OrderFaq from '../components/OrderFaq'

const OrderPage = () => {
    const dispatch = useDispatch()
    const newOrderData = useSelector((state) => state.orderCreate)
    const { loading, success } = newOrderData

    const [dealerName, setDealerName] = useState('');
    const [dealerContact, setDealerContact] = useState('');
    const [productType, setProductType] = useState('');
    const [productMaterial, setProductMaterial] = useState('');
    const [orderRemark, setOrderRemark] = useState('');

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [control, setControl] = useState('right');
    const [lift, setLift] = useState('corded');
    const [mount, setMount] = useState('inside');
    const [remark, setRemark] = useState('');
    const [order, setOrder] = useState([]);

    const [editOrderId, setEditOrderId] = useState(null);
    const [widthEdit, setWidthEdit] = useState(0);
    const [heightEdit, setHeightEdit] = useState(0);
    const [controlEdit, setControlEdit] = useState('right');
    const [liftEdit, setLiftEdit] = useState('corded');
    const [mountEdit, setMountEdit] = useState('inside');
    const [remarkEdit, setRemarkEdit] = useState('');
    const [editFormData, setEditFormData] = useState({
        width: 0,
        height: 0,
        control: '',
        lift: '',
        mount: '',
        remark: ''
    });

    //- Adding data into the array 
    const handleAddDataSubmit = (e) => {
        e.preventDefault()
        const newOrder = {
            id: nanoid(),
            width,
            height,
            control,
            lift,
            mount,
            remark
        };
        const updateOrder = [...order, newOrder];
        setOrder(updateOrder);
    }

    //_ Editing the object inside the Array
    const handleEditClick = (e, editOrder) => {
        setEditOrderId(editOrder.id)
        setWidthEdit(editOrder.width)
        setHeightEdit(editOrder.height)
        setControlEdit(editOrder.control)
        setLiftEdit(editOrder.lift)
        setMountEdit(editOrder.mount)
        setRemarkEdit(editOrder.remark)
        setEditFormData({ ...editOrder })
    }

    const handleEditDataSubmit = () => {
        const newOrder = [...order]
        const index = order.findIndex((o) => o.id === editOrderId)
        newOrder[index] = {
            id: editOrderId,
            width: widthEdit,
            height: heightEdit,
            control: controlEdit,
            lift: liftEdit,
            mount: mountEdit,
            remark: remarkEdit
        }
        setOrder(newOrder)
        console.log("🚀 ~ file: OrderPage.js ~ line 71 ~ handleEditDataSubmit ~ newOrder", newOrder)
        setEditOrderId(null)
    }

    //+ Cancel the data object
    const handleCancelData = () => {
        setEditOrderId(null)
    }

    //= Delete the row
    const handleDeleteData = (orderId) => {
        const newOrder = [...order]
        const index = order.findIndex((o) => o.id === orderId)
        newOrder.splice(index, 1);
        setOrder(newOrder)
    }

    //| Creating the order
    const handleSubmitOrder = () => {
        const form = {
            dealerName,
            dealerContact,
            productType,
            productMaterial,
            remark: orderRemark,
            orderArray: order
        }
        dispatch(CreateOrderAction(form))
    }

    return (
        <>
            <main className="p-3">
                {
                    success ? (<Badge p={{
                        type: 'success', message: 'Your order has been created successfully👍',
                        redirectName: 'Click here to submit another order🙂', redirect: '/public/order'
                    }} />) :
                        (loading ? <Loader /> : (
                            <>
                                {/*breadcrumb*/}
                                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                                    <div className="breadcrumb-title pe-3">Tables</div>
                                    <div className="ps-3">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0 p-0">
                                                <li className="breadcrumb-item">
                                                    <a href="https://dbsblinds.ca/">
                                                        <i className="bx bx-home-alt" />
                                                    </a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    Order Table
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                {/*end breadcrumb*/}
                                <div className="row mt-5 mb-4">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <h6 className="mb-2 text-uppercase">Order Info</h6>
                                        </div>
                                        <div className="col-lg-2">
                                            <div class="form-group">
                                                <button class="btn btn-success w-100"
                                                    type="submit" onClick={handleSubmitOrder}
                                                >
                                                    Submit the Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 row g-3">
                                        <div className="col-lg-6">
                                            <label className="form-label"> Company Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Company"
                                                value={dealerName} onChange={(e) => setDealerName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label"> Contact </label>
                                            <input
                                                type="telephone"
                                                className="form-control"
                                                placeholder="Dealer - phone/email"
                                                value={dealerContact} onChange={(e) => setDealerContact(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label"> Product Type</label>
                                            <select className="form-select"
                                                value={productType} onChange={(e) => setProductType(e.target.value)}>
                                                <option></option>
                                                <option value='Fauxwood'>2" Faux wood</option>
                                                <option value='Zebra'>Zebra Blinds</option>
                                                <option value='Roller-shades'>Roller Shades</option>
                                                <option value='Vertical-PVC'>Vertical PVC</option>
                                                <option value='Honeycomb'>Honeycomb</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="form-label"> Product Material</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Fabric/Material"
                                                value={productMaterial} onChange={(e) => setProductMaterial(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-lg-6 row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Order Remark</label>
                                            <textarea
                                                className="form-control"
                                                placeholder=" Remarks, order tag..."
                                                rows={5}
                                                cols={4}
                                                defaultValue={""}
                                                value={orderRemark} onChange={(e) => setOrderRemark(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h6 className="mb-0 text-uppercase">Order Data Table </h6>
                                <hr />
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table
                                                id="example"
                                                className="table table-striped table-bordered"
                                                style={{ width: "100%" }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Width</th>
                                                        <th>Height</th>
                                                        <th>Control</th>
                                                        <th>Lift</th>
                                                        <th>Mount</th>
                                                        <th>Remarks</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.map((o, index) => (
                                                        <>
                                                            {
                                                                editOrderId === o.id ? (
                                                                    <tr>
                                                                        <td>{'index'}</td>
                                                                        <td className="col-2">
                                                                            <input type="text" className="form-control" placeholder="Width"
                                                                                value={widthEdit} onChange={(e) => setWidthEdit(e.target.value)}
                                                                            />
                                                                        </td>
                                                                        <td className="col-2">
                                                                            <input type="text" className="form-control" placeholder="Width"
                                                                                value={heightEdit} onChange={(e) => setHeightEdit(e.target.value)}
                                                                            />
                                                                        </td>
                                                                        <td className="col-1">
                                                                            <input type="text" className="form-control" placeholder="control"
                                                                                value={controlEdit} onChange={(e) => setControlEdit(e.target.value)}
                                                                            />
                                                                        </td>
                                                                        <td className="col-2">
                                                                            <input type="text" className="form-control" placeholder="control"
                                                                                value={liftEdit} onChange={(e) => setLiftEdit(e.target.value)}
                                                                            />
                                                                        </td>
                                                                        <td className="col">
                                                                            <input type="text" className="form-control" placeholder="control"
                                                                                value={mountEdit} onChange={(e) => setMountEdit(e.target.value)}
                                                                            />
                                                                        </td>
                                                                        <td className="col">
                                                                            <input type="text" className="form-control" placeholder="control"
                                                                                value={remarkEdit} onChange={(e) => setRemarkEdit(e.target.value)}
                                                                            />
                                                                        </td>
                                                                        <td className="col-1 mt-4 " >
                                                                            <div >
                                                                                <button type='submit' onClick={handleEditDataSubmit}
                                                                                    className="mt-1 btn btn-primary  btn-sm px-3 w-100">
                                                                                    Add
                                                                                </button>
                                                                            </div>
                                                                            <div >
                                                                                <button type='submit' onClick={handleCancelData}
                                                                                    className="mt-1 btn btn-danger  btn-sm w-100">
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{o.width}</td>
                                                                        <td>{o.height}</td>
                                                                        <td>{o.control}</td>
                                                                        <td>{o.lift}</td>
                                                                        <td>{o.mount}</td>
                                                                        <td>{o.remark}</td>
                                                                        <td>
                                                                            <div class="table-actions d-flex align-items-center gap-3 fs-6">
                                                                                <button onClick={(e) => handleEditClick(e, o)} className="text-warning bg-dark" data-bs-toggle="tooltip"
                                                                                    data-bs-placement="bottom" title="Edit">
                                                                                    <i className="bi bi-pencil-fill"></i>
                                                                                </button>
                                                                                <a href="javascript:;" className="text-primary" data-bs-toggle="tooltip"
                                                                                    data-bs-placement="bottom" title="Views"><i className="bi bi-eye-fill"></i>
                                                                                </a>
                                                                                <button onClick={() => handleDeleteData(o.id)} className="text-danger bg-dark" data-bs-toggle="tooltip"
                                                                                    data-bs-placement="bottom" title="Delete"><i className="bi bi-trash-fill"></i>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h6 className="mb-0 text-uppercase mb-2">Add Product </h6>
                                <div className="row mb-3">
                                    <div className="col-2">
                                        <label className="form-label">Width</label>
                                        <input type="text" className="form-control" placeholder="Width"
                                            value={width} onChange={(e) => setWidth(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <label className="form-label">Height</label>
                                        <input type="text" className="form-control" placeholder="Width"
                                            value={height} onChange={(e) => setHeight(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-1">
                                        <label className="form-label">Control</label>
                                        <input type="text" className="form-control" placeholder="control"
                                            value={control} onChange={(e) => setControl(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <label className="form-label">Lift</label>
                                        <input type="text" className="form-control" placeholder="control"
                                            value={lift} onChange={(e) => setLift(e.target.value)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Mount</label>
                                        <input type="text" className="form-control" placeholder="control"
                                            value={mount} onChange={(e) => setMount(e.target.value)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Remark</label>
                                        <input type="text" className="form-control" placeholder="control"
                                            value={remark} onChange={(e) => setRemark(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-1 mt-4" >
                                        <form onSubmit={handleAddDataSubmit}>
                                            <button type='submit' onSubmit={handleAddDataSubmit}
                                                className="mt-1 btn btn-primary">
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <hr />
                                <OrderFaq />
                            </>
                        ))}
            </main>

        </>
    )
}

export default OrderPage